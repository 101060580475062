import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "./axiosConfig";

export default function ChangePlanConfirmPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentPlan, newPlan } = location.state || {};

  const [userEmail, setUserEmail] = useState(null); // State to store the user's email
  const [loading, setLoading] = useState(true); // Loading state for fetching user info

  // Fetch the user's email on component mount
  useEffect(() => {
    const fetchUserEmail = async () => {
      try {
        const token = localStorage.getItem("authToken");
        if (!token) {
          throw new Error("Authentication token not found.");
        }

        const response = await axios.get("/api/account", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUserEmail(response.data.email);
      } catch (err) {
        console.error("Error fetching user email:", err);
        alert("Failed to retrieve user information. Please try again.");
        navigate("/account");
      } finally {
        setLoading(false);
      }
    };
    fetchUserEmail();
  }, [navigate]);

  // Alert if a plan change is already scheduled
  useEffect(() => {
    if (currentPlan?.scheduledChangeDate) {
      alert(
        `A plan change to ${currentPlan.scheduledPlan || "another plan"} is already scheduled for ${currentPlan.scheduledChangeDate}.`
      );
    }
  }, [currentPlan]);

  // Handle missing plan information
  if (!currentPlan || !newPlan) {
    console.error("Missing currentPlan or newPlan in location.state");
    return (
      <div className="flex flex-col items-center bg-gray-50 min-h-screen py-8 px-4">
        <div className="max-w-lg w-full bg-white shadow-lg rounded-lg p-6 text-center">
          <h1 className="text-2xl font-bold text-gray-800 mb-6">
            Missing Information
          </h1>
          <p className="text-gray-700 mb-4">
            We could not retrieve the details for your current or new plan.
            Please go back and select a plan.
          </p>
          <button
            onClick={() => navigate("/change-plan")}
            className="px-4 py-2 bg-slate-900 text-white rounded-md hover:bg-slate-700"
          >
            Back to Plans
          </button>
        </div>
      </div>
    );
  }

  // Format subscription price
  const formatPrice = (plan, type) => {
    if (!plan || !plan[type]) {
      return "Free"; // Handle missing pricing gracefully
    }
    return `${plan[type].toLocaleString()}.00 THB`;
  };

/// Handle confirmation of plan change
const handleConfirm = async () => {
  try {
    const token = localStorage.getItem("authToken");
    if (!token || !userEmail) {
      throw new Error("Authentication token or user email not found.");
    }

    // Determine if it's an upgrade or downgrade
    const isUpgrade =
    currentPlan.accountType === "free" || // Upgrading from Free to any paid plan
    newPlan.accountType !== currentPlan.accountType || // Changing to a different plan
    (newPlan.accountType === currentPlan.accountType && // Same plan type but commitment change
     newPlan.subscriptionCommitment === "yearly" &&
     currentPlan.subscriptionCommitment === "monthly"); // Monthly → Yearly upgrade
  
    if (isUpgrade) {
      // Create purchase for upgrades
      const response = await axios.post(
        "/create-purchase",
        {
          email: userEmail,
          plan: newPlan.accountType,
          subscription_commitment: newPlan.subscriptionCommitment || "monthly",
          is_plan_change: true, // Indicate a plan change
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Redirect to the payment gateway
      if (response.data.paymentLink) {
        window.location.href = response.data.paymentLink;
      } else {
        throw new Error("Payment link not provided in response.");
      }
    } else {
      // Handle direct updates for downgrades or switching within free tier
      await axios.patch(
        "/api/account/plan",
        {
          email: userEmail,
          account_type: newPlan.accountType,
          subscription_commitment: newPlan.subscriptionCommitment,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
        );
      alert("Your plan has been successfully updated!");
      navigate("/account");
    }
  } catch (error) {
    console.error("Error handling plan change:", error);
    alert(error.response?.data?.detail || "Failed to process the plan change. Please try again.");
  }
};




  if (loading) {
    return (
      <div className="flex flex-col items-center bg-gray-50 min-h-screen py-8 px-4">
        <div className="max-w-lg w-full bg-white shadow-lg rounded-lg p-6 text-center">
          <h1 className="text-2xl font-bold text-gray-800 mb-6">
            Loading...
          </h1>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center bg-gray-50 min-h-screen py-8 px-4">
      <div className="max-w-4xl w-full bg-white shadow-lg rounded-lg p-6">
        <h1 className="text-2xl font-bold text-gray-800 mb-6 text-center">
          Confirm Plan Change
        </h1>

        {/* Two-Column Layout */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Current Plan */}
          <div className="flex flex-col bg-gray-100 border border-gray-300 rounded-lg p-4">
            <h2 className="text-lg font-semibold text-gray-700 mb-4">
              Your Current Plan
            </h2>
            <div className="flex-grow">
              <h3 className="font-bold text-gray-800">
                {currentPlan.name || "N/A"} Plan
              </h3>
              <ul className="mt-2 space-y-2">
                {currentPlan.features?.map((feature, index) => (
                  <li key={index} className="text-gray-600">
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
            <p className="mt-4 font-bold text-gray-900">
              {currentPlan.subscriptionCommitment === "yearly"
                ? `Yearly: ${formatPrice(currentPlan, "yearlyPrice")}`
                : `Monthly: ${formatPrice(currentPlan, "monthlyPrice")}`}
            </p>
          </div>

          {/* New Plan */}
          <div className="flex flex-col bg-gray-900 border border-slate-600 rounded-lg p-4">
            <h2 className="text-lg font-semibold text-white mb-4">
              Your New Plan
            </h2>
            <div className="flex-grow">
              <h3 className="font-bold text-white">
                {newPlan.name || "N/A"} Plan
              </h3>
              <ul className="mt-2 space-y-2">
                {newPlan.features?.map((feature, index) => (
                  <li
                    key={index}
                    className={
                      currentPlan.features?.includes(feature)
                        ? "text-white"
                        : "text-white font-semibold"
                    }
                  >
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
            <p className="mt-4 font-bold text-white">
              {newPlan.subscriptionCommitment === "yearly"
                ? `Yearly: ${formatPrice(newPlan, "yearlyPrice")}`
                : `Monthly: ${formatPrice(newPlan, "monthlyPrice")}`}
            </p>
          </div>
        </div>

        {/* Actions */}
        <div className="flex justify-between items-center mt-8">
          <button
            onClick={() => navigate("/change-plan")}
            className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400"
          >
            Cancel
          </button>
          <button
            onClick={handleConfirm}
            className="px-4 py-2 bg-slate-900 text-white border-2 border-slate-900 rounded-md hover:bg-white hover:text-black"
          >
            Confirm Change
          </button>
        </div>
      </div>
    </div>
  );
}
