import React from "react";
import { useNavigate } from "react-router-dom";

export default function KhornWorkSpace() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center bg-white-900 min-h-screen py-8 px-4">
      {/* Title Section */}
      <div className="w-full text-center mb-8">
        <h1 className="text-4xl font-bold text-gray-800">Welcome to KHORN Workspace</h1>
        <p className="text-gray-600 mt-4">
          Choose KHORN mode
        </p>
      </div>

      {/* Workspace Options */}
      <div className="max-w-4xl w-full grid grid-cols-1 md:grid-cols-3 gap-6">
        {/* Search Workspace Option */}
        <div
            onClick={() => navigate("/document-search")}
            className="cursor-pointer bg-white border border-black rounded-lg p-6 shadow-lg hover:bg-black transition duration-300 group"
            >
            <h2 className="text-2xl font-semibold text-black mb-4 text-center group-hover:text-white transition duration-300">
                KHORN Search
            </h2>
            <p className="text-black text-center group-hover:text-white transition duration-300">
                Find legal documents and resources quickly and efficiently.
            </p>
        </div>



        {/* Note Workspace Option */}
        <div
          onClick={() => navigate("/note-workspace")}
          className="cursor-pointer bg-white border border-black rounded-lg p-6 shadow-lg hover:bg-black transition duration-300 group"
        >
          <h2  className="text-2xl font-semibold text-black mb-4 text-center group-hover:text-white transition duration-300">
            KHORN Research</h2>
          <p  className="text-black text-center group-hover:text-white transition duration-300">
            Create, manage, and edit your research notes seamlessly.
          </p>
        </div>

        {/* Review Workspace Option */}
        <div
          onClick={() => navigate("/review-workspace")}
          className="cursor-pointer bg-white border border-black rounded-lg p-6 shadow-lg hover:bg-black transition duration-300 group"
        >
          <h2  className="text-2xl font-semibold text-black mb-4 text-center group-hover:text-white transition duration-300">
            KHORN Review
            
          </h2>
          <p className="text-black text-center group-hover:text-white transition duration-300">
            Upload, review, and analyze your documents with ease.
          </p>
        </div>
      </div>
    </div>
  );
}
