import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "./axiosConfig";

export default function AccountPage() {
  const [userInfo, setUserInfo] = useState(null);
  const [error, setError] = useState("");
  const navigate = useNavigate(); // For navigation

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const token = localStorage.getItem("authToken");
        if (!token) {
          throw new Error("Authentication token not found.");
        }

        const response = await axios.get("/api/account", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUserInfo(response.data);
      } catch (err) {
        console.error("Error fetching user information:", err);
        setError(err.response?.data?.detail || "Failed to fetch user information.");
      }
    };
    fetchUserInfo();
  }, []);

  if (error) {
    return <div className="p-6 bg-red-100 text-red-800 rounded-lg">{error}</div>;
  }

  if (!userInfo) {
    return <div className="p-6 bg-gray-100 text-gray-800 rounded-lg">Loading...</div>;
  }

  // Update button text logic for free and pro only
  const getButtonText = () => {
    if (userInfo.account_type.toLowerCase() === "free") {
      return "Upgrade to Pro";
    } else if (userInfo.account_type.toLowerCase() === "pro") {
      return "Manage Plan";
    }
    return "Update Plan"; // Default fallback (though unnecessary with the current plans)
  };

  // Navigation handler
  const handleNavigate = () => {
    navigate("/change-plan", {
      state: {
        accountType: userInfo.account_type, // free or pro
        subscriptionCommitment: userInfo.subscription_commitment, // monthly or yearly
      },
    });
  };

  return (
    <div className="flex flex-col items-center bg-gray-50 min-h-screen py-8 px-4">
      <div className="bg-white shadow-lg rounded-lg p-6 max-w-md w-full">
        <h1 className="text-2xl font-bold text-gray-800 mb-6">
          {userInfo.username} Account Information
        </h1>

        <div className="space-y-4">
          <div className="flex justify-between">
            <span className="font-medium text-gray-600">Username:</span>
            <span className="text-gray-800">{userInfo.username}</span>
          </div>
          <div className="flex justify-between">
            <span className="font-medium text-gray-600">Email:</span>
            <span className="text-gray-800">{userInfo.email}</span>
          </div>
          <div className="flex justify-between">
            <span className="font-medium text-gray-600">Account Type:</span>
            <span className="text-gray-800">{userInfo.account_type}</span>
          </div>
          <div className="flex justify-between">
            <span className="font-medium text-gray-600">Subscription:</span>
            <span className="text-gray-800">
  {userInfo.subscription_commitment ? userInfo.subscription_commitment : "free"}
</span>

          </div>
          <div className="flex justify-between">
            <span className="font-medium text-gray-600">Plan Status:</span>
          <span
            className={`text-gray-800 ${
              userInfo.status === "active" ? "text-green-600" : "text-red-600"
            }`}
          >
            {userInfo.status === "active" ? userInfo.status : "Trial"}
          </span>

          </div>
          <div className="flex justify-between">
            <span className="font-medium text-gray-600">Signup At:</span>
            <span className="text-gray-800">
              {new Date(userInfo.signup_datetime).toLocaleDateString()}
            </span>
          </div>
        </div>

        {/* Unified Upgrade/Downgrade Button */}
        <div className="mt-6">
          <button
            className="w-full mt-2 px-4 border border-black py-2 bg-white text-black rounded-md hover:bg-slate-900 hover:text-white mb-4"
            onClick={handleNavigate}
          >
            {getButtonText()}
          </button>
        </div>
      </div>
    </div>
  );
}
