import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Upload, Loader2 } from 'lucide-react';
import axios from './axiosConfig';

export default function DocReview() {
  const [fileUrl, setFileUrl] = useState('');
  const [fileName, setFileName] = useState('');
  const [inputText, setInputText] = useState('');
  const [summary, setSummary] = useState('');
  const [favorScore, setFavorScore] = useState(null);
  const [favorReasoning, setFavorReasoning] = useState('');
  const [isPreviewCollapsed, setIsPreviewCollapsed] = useState(true); // Initially collapsed

  const togglePreview = () => {
    setIsPreviewCollapsed((prev) => !prev);
  };
  const [partyNames, setPartyNames] = useState({
    partyOneName: 'Party One',
    partyTwoName: 'Party Two',
  });
  const [questions, setQuestions] = useState({
    partyOneQuestions: [],
    partyTwoQuestions: [],
  });
  const [recommendations, setRecommendations] = useState({
    partyOneRecommendations: [],
    partyTwoRecommendations: [],
  });
  
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);

  const closeModal = () => {
    setShowErrorModal(false);
  };

  function formatMessage(text) {
    return text
      .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
      .replace(/\n{2,}/g, '<br /><br />')
      .replace(/\n/g, '<br />');
  }

  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (!file) return;
  
    setErrorMessage('');
    setIsLoading(true);
    setIsPreviewCollapsed(false);
  
    try {
  
      let text = '';
      let pageCount = 0; // For PDF page count validation
  
      if (file.type === 'application/pdf') {
        const { GlobalWorkerOptions } = await import('pdfjs-dist/build/pdf.min.mjs');
        GlobalWorkerOptions.workerSrc = '/pdf.worker.min.mjs';
  
        const arrayBuffer = await file.arrayBuffer();
        const pdfjsLib = await import('pdfjs-dist/build/pdf.min.mjs');
        const pdfData = new Uint8Array(arrayBuffer);
        const loadingTask = pdfjsLib.getDocument({ data: pdfData });
        const pdf = await loadingTask.promise;
  
        pageCount = pdf.numPages;
        if (pageCount > 20) {
          setErrorMessage('Document exceeds the 20-page limit.');
          setShowErrorModal(true);
          console.log("Page limit exceeded. Returning early.");
          return; // This stops the execution for invalid files.
        }
  
        let rawText = '';
        for (let i = 1; i <= pdf.numPages; i++) {
          const page = await pdf.getPage(i);
          const textContent = await page.getTextContent();
          rawText += textContent.items.map((item) => item.str).join('') + '\n';
        }
        text = rawText;
      } else if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        const arrayBuffer = await file.arrayBuffer();
        const mammoth = await import('mammoth');
        const { value } = await mammoth.extractRawText({ arrayBuffer });
        text = value.replace(/\n+/g, '\n').trim();
      } else if (file.type === 'text/plain') {
        text = await file.text();
      } else {
        throw new Error('Unsupported file type');
      }
  
      // Character count validation
      if (text.length > 10000) {
        setErrorMessage('Document exceeds the 10,000-character limit.');
        setShowErrorModal(true);
        console.log("Character limit exceeded. Returning early.");
        return; // This stops the execution for invalid files.
      }
      const objectUrl = URL.createObjectURL(file);
      setFileUrl(objectUrl);
      setFileName(file.name);
      setInputText(text);

  
      // Call APIs for summarization, favorability, and questions
      console.log("File validated successfully. Proceeding to API calls...");
      const [summaryResponse, favorabilityResponse, questionResponse] = await Promise.all([
        axios.post('/api/doc-summarize', { text }),
        axios.post('/api/favorability', { text }),
        axios.post('/api/questions', { text }),
      ]);
      console.log("API calls completed successfully.");

      // Update state with responses
      setSummary(summaryResponse.data.summary);
      setFavorScore(favorabilityResponse.data.favor_score);
      setFavorReasoning(favorabilityResponse.data.favor_reasoning_and_observation);
  
      setPartyNames({
        partyOneName: favorabilityResponse.data.party_one_name || 'Party One',
        partyTwoName: favorabilityResponse.data.party_two_name || 'Party Two',
      });
  
      setQuestions({
        partyOneQuestions: questionResponse.data.party_one_questions || [],
        partyTwoQuestions: questionResponse.data.party_two_questions || [],
      });
  
      // Recommendations API Call
      const recommendationResponse = await axios.post('/api/recommendations', {
        text,
        summary: summaryResponse.data.summary,
        favor_score: favorabilityResponse.data.favor_score,
        favor_reasoning_and_observation: favorabilityResponse.data.favor_reasoning_and_observation,
        party_one_questions: questionResponse.data.party_one_questions || [],
        party_two_questions: questionResponse.data.party_two_questions || [],
        party_one_name: favorabilityResponse.data.party_one_name,
        party_two_name: favorabilityResponse.data.party_two_name,
      });
  
      setRecommendations({
        partyOneRecommendations: recommendationResponse.data.party_one_recommendations || [],
        partyTwoRecommendations: recommendationResponse.data.party_two_recommendations || [],
      });
    } catch (error) {
      console.error('Error processing file or analyzing:', error.message);
      setErrorMessage(error.message || 'Failed to process or analyze the file. Please try again.');
      setShowErrorModal(true);
    } finally {
      setIsLoading(false);
      setIsPreviewCollapsed(true);
    }
  }, []);
  


  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'text/plain': ['.txt'],
    },
    multiple: false,
  });

  // chat with document

  const [chatInput, setChatInput] = useState('');
  const [chatHistory, setChatHistory] = useState([]);

  const handleChatSubmit = async (e) => {
    e.preventDefault();

    if (!chatInput.trim()) return;

    const newChat = { user: chatInput, bot: 'Loading...' };
    setChatHistory((prev) => [...prev, newChat]);

    try {
      const response = await axios.post('/api/chat', {
        text: inputText,
        summary,
        favor_score: favorScore,
        favor_reasoning: favorReasoning,
        questions,
        recommendations,
        user_query: chatInput,
      });

      const botResponse = response.data.response || 'No response available.';
      setChatHistory((prev) => {
        const updated = [...prev];
        updated[updated.length - 1].bot = botResponse;
        return updated;
      });
    } catch (error) {
      setChatHistory((prev) => {
        const updated = [...prev];
        updated[updated.length - 1].bot = 'Error: Unable to fetch response.';
        return updated;
      });
    } finally {
      setChatInput('');
    }
  };
  const SkeletonLoader = ({ rows = 4, className = '' }) => {
    return (
      <div className={`animate-pulse space-y-4 ${className}`}>
        {Array.from({ length: rows }).map((_, idx) => (
          <div
            key={idx}
            className={`bg-gray-300 h-4 ${
              idx === 0 ? 'w-full' : idx === 1 ? 'w-3/4' : 'w-1/2'
            } rounded`}
          />
        ))}
      </div>
    );
  };
  

  return (
    <div className="container mx-auto py-3">
      {!fileName ? (
        <div className="h-screen flex flex-col items-center justify-center">
          <h1 className="text-2xl font-semibold text-gray-800 mb-4">อัพโหลดเอกสารเพื่อทำการสรุปและคิดประเด็น</h1>
          <div
            {...getRootProps()}
            className={`border-2 border-dashed rounded-lg text-center cursor-pointer ${
              isDragActive ? 'border-blue-500' : 'border-gray-300 hover:border-gray-400'
            } transition flex items-center justify-center`}
            style={{ width: '100%', height: '40%' }}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <p className="text-gray-500">ลากไฟล์วางลงตรงนี้...</p>
            ) : (
              <div>
                <Upload className="mx-auto h-16 w-16 text-gray-400" />
                <p className="text-gray-600">ลากไฟล์วางลงตรงนี้ได้เลย หรือกดเพื่อเลือกไฟล์</p>
                <p className="text-sm text-gray-500">รองรับไฟล์ประเภท: .txt, .docx, .pdf</p>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-6">
          <div className="flex justify-between items-center">
            <h1 className="text-2xl font-semibold text-gray-800">Analysis Mode</h1>
            <button
              onClick={() => {
                setFileName('');
                setFileUrl('');
                setInputText('');
                setSummary('');
                setFavorScore(null);
                setFavorReasoning('');
                setPartyNames({ partyOneName: 'Party One', partyTwoName: 'Party Two' });
                setQuestions({ partyOneQuestions: [], partyTwoQuestions: [] });
              }}
              className="bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700"
            >
              เปลี่ยนเอกสาร
            </button>
          </div>

          <div className="flex gap-6">
            {/* Left Panel: Document Preview */}
            {/* Collapsible Sidebar */}
          <div
            className={`transition-transform transform ${
              isPreviewCollapsed ? '-translate-x-full' : 'translate-x-0'
            } fixed z-10 inset-y-0 left-0 bg-gray-100 shadow-lg w-2/4 p-4`}
          >
            <button
              onClick={togglePreview}
              className="absolute -right-8 top-4 bg-gray-100 border border-gray-300 rounded-full p-2 shadow-md focus:outline-none"
            >
              {isPreviewCollapsed ? '>' : '<'}
            </button>
            <h2 className="text-lg font-semibold text-gray-700 mb-2">เอกสาร</h2>
            {fileName.endsWith('.pdf') ? (
              <embed src={fileUrl} type="application/pdf" className="w-full h-[calc(100vh-100px)]" />
            ) : (
              <pre className="text-sm text-gray-600 whitespace-pre-wrap h-[calc(100vh-100px)] overflow-auto">
                {inputText}
              </pre>
            )}
          </div>
          <div className='ml-2 mx-auto my-auto w-screen'>
            {/* Right Panel: Analysis Blocks */}
            <div className="grid grid-cols-2 gap-4">
              {/* Summary Block */}
              <div className="border rounded-2xl p-4 bg-white">
                <h2 className="text-lg font-semibold text-gray-700 mb-2">สรุปจากเอกสาร</h2>
                {isLoading ? (
                <div>
                <SkeletonLoader rows={4} />
              </div>
                ) : (
                  <div
                  className="text-sm text-gray-600 whitespace-pre-wrap"
                  dangerouslySetInnerHTML={{
                    __html: formatMessage(summary),
                  }}
                />
                )}
              </div>

              {/* Favorability Block */}
              <div className="border rounded-2xl p-4 bg-white">
                  <h2 className="text-lg font-semibold text-gray-700 mb-2">การวิเคราะห์ความเท่าเทียมเอกสาร</h2>
                  {isLoading ? (
                <div>
                 <SkeletonLoader rows={4} />
              </div>
                  ) : (
                    <div>
                      <p className="text-sm text-gray-600 mb-4">
                        <strong>เข้าข้าง:</strong>{' '}
                        {favorScore < 5
                          ? ` ${partyNames.partyOneName || 'Party One'}`
                          : favorScore > 5
                          ? ` ${partyNames.partyTwoName || 'Party Two'}`
                          : 'เป็นกลาง'}
                      </p>

                      {/* Favorability Bar */}
                      <div className="relative w-full h-6 bg-gray-200 rounded-xl overflow-hidden shadow-sm">
                      {/* Party One Segment */}
                      <div
                        className={`absolute h-full transition-all duration-300 ease-in-out border-r-4 border-gray-400 ${
                          favorScore < 5 ? 'bg-gradient-to-r from-green-500 to-green-400' : 'bg-gray-200'
                        }`}
                        style={{ width: `${(10 - favorScore) * 10}%` }}
                      >
                        {favorScore < 5 && (
                          <span className="absolute left-5 top-1 text-xs text-white font-semibold">
                            {partyNames.partyOneName || 'Party One'} ได้เปรียบ
                          </span>
                        )}
                      </div>

                      {/* Party Two Segment */}
                      <div
                        className={`absolute right-0 h-full transition-all duration-300 ease-in-out border-l-4 border-gray-400 ${
                          favorScore > 5 ? 'bg-gradient-to-r from-green-500 to-green-400' : 'bg-gray-400'
                        }`}
                        style={{ width: `${favorScore * 10}%` }}
                      >
                        {favorScore > 5 && (
                          <span className="absolute right-5 top-1 text-xs text-white font-semibold">
                            {partyNames.partyTwoName || 'Party Two'} ได้เปรียบ
                          </span>
                        )}
                      </div>

                      {/* Neutral Indicator */}
                      {favorScore === 5 && (
                        <div className="absolute w-full h-full flex items-center justify-center bg-green-500 text-white font-semibold text-xs">
                          Neutral
                        </div>
                      )}
                    </div>


                      {/* Bar Labels */}
                      <div className="flex justify-between mt-2 text-sm font-semibold">
                        {/* Party One Label */}
                        <span
                          className={`${
                            favorScore < 5
                              ? 'text-green-600' // Advantage
                              : favorScore > 5
                              ? 'text-red-600' // Disadvantage
                              : 'text-gray-700' // Neutral
                          }`}
                        >
                          {partyNames.partyOneName || 'Party One'}
                        </span>

                        {/* Party Two Label */}
                        <span
                          className={`${
                            favorScore > 5
                              ? 'text-green-600' // Advantage
                              : favorScore < 5
                              ? 'text-red-600' // Disadvantage
                              : 'text-gray-700' // Neutral
                          }`}
                        >
                          {partyNames.partyTwoName || 'Party Two'}
                        </span>
                      </div>

                      {/* Reasoning */}
                      <p className="mt-4 text-sm text-gray-600 whitespace-pre-wrap">
                        <strong>เหตุผล:</strong> {favorReasoning}
                      </p>
                    </div>
                  )}
                </div>

              </div>

              <div className="grid grid-cols-2 gap-4 mt-4">
  {/* Party One Section */}
  <div
    className={`border rounded-2xl p-4 ${
      favorScore < 5
        ? 'bg-green-100' // Advantage for Party One
        : 'bg-red-100'  // Disadvantage for Party One
    }`}
  >
    <h2 className="text-lg font-semibold text-gray-700 mb-2">{partyNames.partyOneName}</h2>
    {isLoading ? (
    <div>
    <SkeletonLoader rows={4} />
  </div>
    ) : (
      <>
        <h3 className="text-md font-semibold text-gray-600 mb-2">คำถาม</h3>
        <ul className="pl-6 text-sm text-gray-600 mb-4">
          {questions.partyOneQuestions.map((q, idx) => (
            <li key={idx}>
              <strong>Q{idx + 1}:</strong> {q}
            </li>
          ))}
        </ul>
        <h3 className="text-md font-semibold text-gray-600 mb-2">คำแนะนำ</h3>
        <ul className="pl-6 text-sm text-gray-600">
          {recommendations.partyOneRecommendations.map((rec, idx) => (
            <li key={idx}>
              <strong>R{idx + 1}:</strong> {rec}
            </li>
          ))}
        </ul>
      </>
    )}
  </div>

  {/* Party Two Section */}
  <div
    className={`border rounded-2xl p-4 ${
      favorScore > 5
        ? 'bg-green-100' // Advantage for Party Two
        : 'bg-red-100'   // Disadvantage for Party Two
    }`}
  >
    <h2 className="text-lg font-semibold text-gray-700 mb-2">{partyNames.partyTwoName}</h2>
    {isLoading ? (
    <div>
 <SkeletonLoader rows={4} />
  </div>
    ) : (
      <>
        <h3 className="text-md font-semibold text-gray-600 mb-2">คำถาม</h3>
        <ul className="pl-6 text-sm text-gray-600 mb-4">
          {questions.partyTwoQuestions.map((q, idx) => (
            <li key={idx}>
              <strong>Q{idx + 1}:</strong> {q}
            </li>
          ))}
        </ul>
        <h3 className="text-md font-semibold text-gray-600 mb-2">คำแนะนำ</h3>
        <ul className="pl-6 text-sm text-gray-600">
          {recommendations.partyTwoRecommendations.map((rec, idx) => (
            <li key={idx}>
              <strong>R{idx + 1}:</strong> {rec}
            </li>
          ))}
        </ul>
      </>
    )}
  </div>
</div>
{/* Chat Component */}
{/* <div className="border rounded-md p-4 bg-white mt-6"> */}
        {/* <h2 className="text-lg font-semibold text-gray-700 mb-4">Chat with Document</h2>
        <div className="h-64 overflow-y-auto mb-4 bg-gray-100 p-4 rounded-md">
          {chatHistory.map((chat, idx) => (
            <div key={idx} className="mb-4">
              <p>
                <strong>User:</strong> {chat.user}
              </p>
              <p>
                <strong>Bot:</strong> {chat.bot}
              </p>
            </div>
          ))}
        </div>
        <form onSubmit={handleChatSubmit} className="flex gap-4">
          <input
            type="text"
            className="flex-grow border border-gray-300 rounded-md p-2"
            placeholder="Ask a question..."
            value={chatInput}
            onChange={(e) => setChatInput(e.target.value)}
          />
          <button
            type="submit"
            className="bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700"
          >
            Send
          </button>
        </form> */}
      {/* </div> */}
    </div>
        </div>
        
        </div>
        
      )}
    </div>
    
  );
}
