import React, { useState, useEffect, useRef } from 'react';
import { EditorContent, useEditor } from '@tiptap/react';
import Color from '@tiptap/extension-color';
import StarterKit from '@tiptap/starter-kit';
import { useLocation} from 'react-router-dom';
import TextStyle from '@tiptap/extension-text-style';
import ListItem from '@tiptap/extension-list-item'
import TipTapLink from '@tiptap/extension-link';
import axios from './axiosConfig';
import './NoteEditor.css'
import Placeholder from '@tiptap/extension-placeholder';
import { useParams } from 'react-router-dom'; // Import useParams to get noteId from the URL
import { ArrowLeft } from 'lucide-react'; // Using Lucide for icons
import { useNavigate } from 'react-router-dom';

import { Trash2, Settings, Share, X, Bold, Minus,
  Italic, List,Globe, ListOrdered, Link, Heading1, RemoveFormatting, Strikethrough, Search, Copy, ArrowUp } from 'lucide-react';

  // Move DocumentList outside NoteEditor
const DocumentList = ({ selectedDocuments, handleDocumentClick, handleDeleteIconClick }) => {
  const [activeTab, setActiveTab] = useState("ทั้งหมด"); // State to track active tab

  // Filter documents based on active tab
  const filteredDocuments = selectedDocuments.filter((item) => {
    if (activeTab === "ทั้งหมด") return true; // Show all documents
    if (activeTab === "ฎีกา") return item.deka_name;
    if (activeTab === "มาตรา") return item.marthar;
    if (activeTab === "เวปไซต์") return item.title;
    return false;
  });

  return (
    <div className="flex flex-col h-5/6">
  {/* Tabs */}

  <div className="flex justify-around">
  {["ทั้งหมด", "ฎีกา", "มาตรา", "เวปไซต์"].map((tab, index) => {
    // Define custom colors for each tab
    const tabColors = {
      "ทั้งหมด": "text-black border-gray-500", 
      "ฎีกา": "text-black border-gray-700", 
      "มาตรา": "text-blue-500 border-blue-500", 
      "เวปไซต์": "text-green-500 border-green-500", 
    };

    return (
      <button
        key={index}
        className={`pb-2 font-medium text-sm transition-colors ${
          activeTab === tab
            ? `${tabColors[tab]} border-b-2` // Active tab style with specific color
            : "text-gray-600 hover:text-gray-800" // Default style for inactive tabs
        }`}
        onClick={() => setActiveTab(tab)} // Set active tab on click
      >
        {tab} {/* Display the tab text directly */}
      </button>
    );
  })}
</div>



  {/* Document List with Fixed Scrollbar */}
  <div className="h-screen mb-3 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200 px-2 py-2"> {/* Fixed height with scrolling */}
    <ul className="space-y-2">
      {filteredDocuments.map((item, index) => {
        // Determine the base styles based on the type
        const baseStyle = item.deka_name
          ? "bg-gradient-to-r from-slate-900 to-slate-600 text-white"
          : item.marthar
          ? "bg-gradient-to-r from-blue-600 to-blue-400 text-white"
          : item.url
          ? "bg-gradient-to-r from-green-600 to-green-300 text-white"
          : "bg-gray-200 text-black"; // Default fallback style

        return (
          <li
            key={item.pdf_path || item.m_pdf_path || item.url || index}
            className={`flex items-center space-x-2 cursor-pointer hover:bg-opacity-90 p-2 rounded-lg ${baseStyle}`}
            onClick={() => handleDocumentClick(item)}
          >
            <div className="flex-1">
              <span
                className="selected-documents-list font-medium"
                title={
                  item.deka_name || item.marthar || item.title || "No Title Available"
                }
              >
                {item.deka_name || item.marthar || item.title || "No Title Available"}
              </span>
              <p className="text-xs text-gray-200">
                {item.deka_name
                  ? "คำพิพากษาฎีกา"
                  : item.marthar
                  ? `มาตรากฎหมาย - ${item.document_name || "No Document Available"}`
                  : item.url
                  ? "Web Reference"
                  : "Unknown"}
              </p>
            </div>
            <Trash2
              className="cursor-pointer text-white hover:text-red-700 transform transition-transform duration-200 hover:scale-125"
              size={18}
              onClick={(e) => {
                e.stopPropagation(); // Prevent triggering document click
                handleDeleteIconClick(item); // Handle deletion
              }}
            />
          </li>
        );
      })}
    </ul>
  </div>
</div>

  );
};


const NoteEditor = ({onClose}) => {
  const location = useLocation();
  const { checkedDocuments } = location.state || { checkedDocuments: [] };
  const [message, setMessage] = useState('');
  const [additionalDocuments, setAdditionalDocuments] = useState([]);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [selectedDocuments, setSelectedDocuments] = useState([]); // Store selected documents
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isNoteSidebarOpen, setIsNoteSidebarOpen] = useState(false);
  const [noteTitle, setNoteTitle] = useState("");
  const [activeTab, setActiveTab] = useState("edit");
  const [isAddModalOpen, setIsAddModalOpen] = useState(false); // State for controlling the upload modal visibility
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [chatSelectedDocuments, setChatSelectedDocuments] = useState([]); // For documents to send in chat
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
  const [docToDelete, setDocToDelete] = useState(null);
  const { noteId } = useParams(); // Get the noteId from the URL
  const [note, setNote] = useState(null); // Initialize the note state
  const [loading, setLoading] = useState(true); // Loading state for fetching data
  const [selectAll, setSelectAll] = useState(false); // select all checkbox
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
  const popupRef = useRef(null);
  const [isTyping, setIsTyping] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null); // State for managing the timeout
  const [selectedDocSummary, setSelectedDocSummary] = useState(''); // Initialize state to hold document summary
  
  const [isSummarizing, setIsSummarizing] = useState(false); // State for loading

  const [answer, setAnswer] = useState(''); // To store the answer from the API
  const [isAnswerLoading, setIsAnswerLoading] = useState(false); // Loading state for answer
  const [rResults, setRResults] = useState([]); // State to store additional references


  const chatEndRef = useRef(null); // Ref to track the end of the chat
  const [isUserScrolling, setIsUserScrolling] = useState(false); // To track if the user is scrolling manually
  const [showScrollButton, setShowScrollButton] = useState(false); // To control the visibility of the scroll button
  
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [dragActive, setDragActive] = useState(false);
  const [uploadFiles, setUploadFiles] = useState([]); // To store selected files
  const [isUploading, setIsUploading] = useState(false); // To track upload status
  const [showUploadSection, setShowUploadSection] = useState(true);

  const [dekaId, setDekaId] = useState('');
  const [dekaYear, setDekaYear] = useState('');
  const [isDekaSearchLoading, setIsDekaSearchLoading] = useState(false); // Loading state for Deka search

  const [summaryNotes, setSummaryNotes] = useState([]); // State to hold summary notes


  const navigate = useNavigate(); // Get the navigate function to go back

  // Function to auto-scroll to the bottom
  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };


  // selectedDocuments State in Real-time
  useEffect(() => {
    console.log("Current selected documents:", selectedDocuments);
  }, [selectedDocuments]);
  

  // Automatically scroll to the bottom when chat opens or new messages are added
  useEffect(() => {
    scrollToBottom(); // Auto scroll to bottom when the component mounts or updates
  }, []);

  

  // Detect if the user scrolls up and stop auto-scroll if they are
const handleChatScroll = (e) => {
  const { scrollTop, scrollHeight, clientHeight } = e.target;

  // If the user is not at the bottom, set scrolling state to true and show the button
  if (scrollTop + clientHeight < scrollHeight - 50) {
    setIsUserScrolling(true);
    setShowScrollButton(true); // Show the "Scroll to Bottom" button
  } else {
    setIsUserScrolling(false);
    setShowScrollButton(false); // Hide the button when user scrolls to the bottom
  }
};


  useEffect(() => {
    if (showScrollButton) {
      console.log("Scroll to Bottom button should be visible");
    }
  }, [showScrollButton]);
  

  // Auto-scroll back to the bottom if the user isn't actively scrolling
  useEffect(() => {
    if (!isUserScrolling) {
      const autoScrollTimeout = setTimeout(() => {
        scrollToBottom();
      }, 300); // A short delay before auto-scrolling down
      return () => clearTimeout(autoScrollTimeout); // Clear timeout on cleanup
    }
  }, [isUserScrolling]);

// Fetch note and set the state with selected documents
useEffect(() => {
  // Fetch the note data by noteId
  const fetchNote = async () => {
    try {
      const token = localStorage.getItem('authToken'); // Get the token from localStorage
      if (!token) {
        console.error("No authentication token found.");
        return;
      }

      // Include the token in the Authorization header
      const response = await axios.get(`/api/notes/${noteId}`, {
        headers: {
          Authorization: `Bearer ${token}`, // Set the Bearer token
        },
      });

      setNote(response.data); // Set the fetched note data
      setLoading(false); // Turn off the loading state
    } catch (error) {
      console.error('Error fetching the note:', error);
      setLoading(false); // Turn off the loading state even on error
    }
  };

  fetchNote();
}, [noteId]); // Re-run the effect whenever noteId changes


  

  // Update the title when the note prop changes
  useEffect(() => {
    if (note && note.title) {
      setNoteTitle(note.title);
    }
  }, [note]);

  // Check NoteID if exist
  useEffect(() => {
    if (note && note.id) {
      console.log('Editing note with ID:', note.id);
    } else if (!note) {
      // Don't log anything until the note has been fully loaded
      // This branch will be silent
    } else if (!note.id) {
      console.warn('No note ID available for update.');
    }
  }, [note]);
  
  // Update the select all checkbox state when individual selections change
useEffect(() => {
  const isAllSelected =
    chatSelectedDocuments.length === selectedDocuments.length &&
    selectedDocuments.length > 0;

  setSelectAll(isAllSelected);
}, [chatSelectedDocuments, selectedDocuments]);

// Update the `useEffect` that initializes the selected documents
useEffect(() => {
  if (note) {
    const allDocuments = note.selected_documents || []; // Fetch all available documents
    setSelectedDocuments(allDocuments); // Set all documents as selected
    setChatSelectedDocuments(allDocuments); // Set chat documents to all by default
    setSelectAll(true); // Set the "Select All" checkbox to checked
    console.log('All documents selected:', allDocuments);
  }
}, [note]);

  
// Inconsistent Document Structure
useEffect(() => {
  if (note) {
      const normalizedDocuments = (note.selected_documents || []).map((doc) => ({
          ...doc,
          deka_name: doc.deka_name || null,
          marthar: doc.marthar || null,
          title: doc.title || null,
          url: doc.url || null,
          content: doc.content || null,
      }));
      setSelectedDocuments(normalizedDocuments);
      console.log('Normalized documents:', normalizedDocuments);
  }
}, [note]);


  // check chat history
  useEffect(() => {
    if (note && note.chat_history) {
        setChatHistory(note.chat_history);
    }
}, [note]);


  const editor = useEditor({
    extensions: [
      Color.configure({ types: [TextStyle.name, ListItem.name] }),
      TextStyle.configure({ types: [ListItem.name] }),
      TipTapLink.configure({ openOnClick: true }),
      StarterKit.configure({
        bulletList: { keepMarks: true, keepAttributes: false },  // Ensure bulletList is enabled
        orderedList: { keepMarks: true, keepAttributes: false }, // Ensure orderedList is enabled
        listItem: { keepMarks: true, keepAttributes: false }, // Enable list items
        heading: { levels: [1, 2, 3] },
      }),
      Placeholder.configure({
        placeholder: 'Note something here...',
      }),
    ],
    content: '',
    onUpdate: ({ editor }) => {
      setIsTyping(true); 
      if (timeoutId) {
        clearTimeout(timeoutId); 
      }

      const newTimeoutId = setTimeout(async () => {
        setIsTyping(false); 
        if (note?.id) {
          try {
            const updatedContent = editor.getHTML(); 
            const token = localStorage.getItem('authToken');
            await axios.patch(`/api/notes/${note.id}`, {
              content: updatedContent, 
              last_edited: new Date().toISOString(),
            }, {
              headers: { Authorization: `Bearer ${token}` },
            });
            console.log('Note updated successfully');
          } catch (error) {
            console.error('Error updating note content:', error);
          }
        }
      }, 1000); 

      setTimeoutId(newTimeoutId); 
    },
    editorProps: {
      attributes: {
        class: 'focus:outline-none border-none',
      },
    },
    placeholder: 'Start typing your notes here...',
  });

  const editorContentRef = useRef(null);

  useEffect(() => {
    // Trigger scroll to bottom after summarization completes and loading ends
    if (!isSummarizing) {
      scrollToBottomEditor(); // Scroll to bottom once summarization is done
    }
  }, [isSummarizing]);
  
  // Function to scroll to the bottom of the editor after the content updates
  const scrollToBottomEditor = () => {
    if (editorContentRef.current) {
      editorContentRef.current.scrollTop = editorContentRef.current.scrollHeight;
    }
  };


  // Cleanup timeout on unmount or note change
  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId, note]);


  // Once the note is fetched, update the editor content
  useEffect(() => {
    if (note && editor) {
      editor.commands.setContent(note.content || '');  // Set editor content with fetched note content
      setNoteTitle(note.title);
      setSelectedDocuments(note.selected_documents || []);
      setChatHistory(note.chat_history || []);
    }
  }, [note, editor]);


  // popup toolbar from highlight text
  useEffect(() => {
    if (editor) {
      editor.on('selectionUpdate', ({ editor }) => {
        const { from, to } = editor.state.selection;
  
        if (from !== to) {
          const { top, left, width } = editor.view.coordsAtPos(from);
          const rectEnd = editor.view.coordsAtPos(to);
          const popupTop = rectEnd.bottom || top; // Get the bottom of the highlighted area
          const popupLeft = left + width / 2; // Center the popup based on the selection
  
          setPopupPosition({
            top: popupTop + window.scrollY, // Add scroll offset
            left: popupLeft + window.scrollX,
          });
  
          setIsPopupVisible(true);
        } else {
          setIsPopupVisible(false);
        }
      });
    }
  }, [editor]);

// Close the popup when clicking outside
useEffect(() => {
  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsPopupVisible(false);
    }
  };
  document.addEventListener('mousedown', handleClickOutside);
  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };
}, []);

  if (loading) {
    return <div>Loading...</div>; // Display loading indicator while fetching the note
  }

  if (!note) {
    return <div>Note not found</div>; // Display message if the note is not found
  }

  
  /// Fucntion for formating text
  
  function cleanText(text) {
    return text.replace(/\n/g, '<br>').replace(/\xa0/g, '&nbsp;');
  }

  function formatMessage(text) {
    // Convert markdown-like bold syntax (**text**) to <strong>text</strong>
    const formattedText = text
      .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') // Convert bold syntax to <strong>
      .replace(/\n{2,}/g, '<br /><br />') // Convert double newlines to two <br /> (new paragraphs)
      .replace(/\n/g, '<br />'); // Convert single newlines to <br /> (line breaks)
  
    return formattedText;
  }
  

  const applyFormatting = (formatType) => {
    if (!editor) return;
  
    switch (formatType) {
      case 'bulletList':
        editor.chain().focus().toggleBulletList().run();  // Handle bullet list
        break;
      case 'orderedList':
        editor.chain().focus().toggleOrderedList().run();  // Handle ordered list
        break;
      case 'h1':
        editor.chain().focus().toggleHeading({ level: 1 }).run();
        break;
      case 'h2':
        editor.chain().focus().toggleHeading({ level: 2 }).run();
        break;
      case 'h3':
        editor.chain().focus().toggleHeading({ level: 3 }).run();
        break;
      case 'bold':
        editor.chain().focus().toggleBold().run();
        break;
      case 'clear':
        editor.chain().focus().clearNodes().run();
        break;
      case 'strike':
        editor.chain().focus().toggleStrike().run();
        break;
      case 'italic':
        editor.chain().focus().toggleItalic().run();
        break;
      case 'horizontal rule':
        editor.chain().focus().setHorizontalRule().run();
        break;
      case 'link':
        const url = prompt('Enter the URL:', 'https://');
        if (url) {
          editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
        }
        break;
      default:
        break;
    }
  };

//   const handleSendMessage = async () => {
//     if (message.trim()) {
//         const userMessage = { sender: 'user', text: message };
//         const updatedChatHistory = [...chatHistory, userMessage];
//         setChatHistory(updatedChatHistory);
//         setMessage(''); // Clear input
//         setIsLoading(true);
//         setIsSummarizing(true);

//         try {
//             // Ensure web references are included in chatSelectedDocuments
//             const allSelectedDocuments = [...chatSelectedDocuments];

//             // Prepare the payload for the API
//             const chatPayload = {
//                 query: message,
//                 selected_documents: allSelectedDocuments,
//                 current_note: note?.content || '', // Include the current note content
//             };
//             console.log('Payload for Chat:', chatPayload);

//             // Send the chat query to the backend
//             const response = await axios.post(
//                 '/api/agent',
//                 chatPayload,
//                 {
//                     headers: {
//                         Authorization: `Bearer ${localStorage.getItem('authToken')}`,
//                     },
//                 }
//             );
//             console.log('this is the response from chat with document', response)
//             // Extract data from response
//             const { answer, documents_used, internal_documents, external_documents } = response.data;

//             // Add bot response to chat history
//             const botMessage = {
//                 sender: 'bot',
//                 text: answer,
//                 documents_used: documents_used,
//             };
//             console.log('Bot Message:', botMessage);

//             const newChatHistory = [...updatedChatHistory, botMessage];
//             console.log('New Chat History:', newChatHistory);
//             setChatHistory(newChatHistory);

//             if (internal_documents && Array.isArray(internal_documents) && internal_documents.length > 0) {
//               const updatedDocuments = [...allSelectedDocuments, ...internal_documents];
//               console.log('Updated Documents before PATCH:', updatedDocuments);
          
//               setChatSelectedDocuments(updatedDocuments);
//               console.log('updatedDocuments:', updatedDocuments);
//               setSelectedDocuments((prevSelected) => {
//                   const combinedDocuments = [...prevSelected, ...internal_documents];
//                   console.log('Combined Selected Documents:', combinedDocuments);
//                   return combinedDocuments;
//               });
          
//               // Persist the updated documents to the backend
//               if (note?.id) {
//                   try {
//                     const sanitizedDocuments = updatedDocuments.map((doc) => ({
//                       ...doc,
//                       ref_marthar: typeof doc.ref_marthar === 'string' ? doc.ref_marthar : null, // Ensure valid string or null
//                   }));
                  
//                   console.log('Sanitized Documents:', sanitizedDocuments);
                  
//                       const payload = {
//                           selected_documents: sanitizedDocuments,
//                           last_edited: new Date().toISOString(),
//                       };
//                       console.log('PATCH Payload:', payload);
          
//                       await axios.patch(`/api/notes/${note.id}`, payload);
          
//                       console.log('Note updated successfully with new documents.');
//                   } catch (error) {
//                       console.error('Error during PATCH request:', error);
//                   }
//               }
//           }
          

//             // Scroll to the bottom after updating chat history
//             scrollToBottom();

//             // Prepare data for re-summarization
//             const currentNoteContent = editor?.getHTML() || ''; // Get current note content
//             const combinedDocuments = [...allSelectedDocuments, ...(internal_documents || [])];
//             const resummarizationPayload = {
//                 current_note: currentNoteContent,
//                 bot_answer: answer,
//                 documents_used: combinedDocuments.map(
//                     (doc) => doc.deka_name || doc.marthar_id || doc.title || 'No Title'
//                 ), // Extract only document names or IDs
//             };
//             console.log('Payload for resummarize:', resummarizationPayload);

//             // Call the resummarize API
//             const resummarizationResponse = await axios.post(
//                 '/api/resummarize',
//                 resummarizationPayload,
//                 {
//                     headers: {
//                         Authorization: `Bearer ${localStorage.getItem('authToken')}`, // Add Authorization header
//                     },
//                 }
//             );

//             // Extract the new summarized content
//             let summarizedContent =
//                 resummarizationResponse.data.summarizedContent || 'No summary available';

//             // Format the summarized content
//             summarizedContent = formatMessage(summarizedContent);

//             // Update the editor content with the new summary
//             if (editor) {
//                 editor.commands.setContent(summarizedContent); // Set the new summarized content
//             }

//             const payload = {
//                 last_edited: new Date().toISOString(),
//                 content: summarizedContent, // Use the updated content from the editor
//                 chat_history: newChatHistory, // Save the updated chat history
//             };
//             console.log('payload for summarize', payload)

//             // Save the updated note to the backend
//             if (note.id) {
//                 await axios.patch(`/api/notes/${note.id}`, payload);
//                 console.log('Note updated successfully with selected documents.');
//             }
//             // Open the NoteSidebar after summarization is finished
//             setIsNoteSidebarOpen(true);
//         } catch (error) {
//             console.error('Error sending message or re-summarizing note:', error);
//         } finally {
//             setIsLoading(false); // Reset loading state
//             setIsSummarizing(false);
//         }
//     }
// };

const handleSendMessage = async () => {
  if (message.trim()) {
      const userMessage = { sender: 'user', text: message };
      const updatedChatHistory = [...chatHistory, userMessage];
      setChatHistory(updatedChatHistory);
      setMessage(''); // Clear input
      setIsLoading(true);
      setIsSummarizing(true);

      try {
          // Combine all selected documents for payload
          const allSelectedDocuments = [...chatSelectedDocuments];

          // Prepare payload for API
          const chatPayload = {
              query: message,
              selected_documents: allSelectedDocuments,
              current_note: note?.content || '', // Include current note content
          };
          console.log('Payload for Chat:', chatPayload);

          // Send chat query to backend
          const response = await axios.post(
              '/api/agent',
              chatPayload,
              {
                  headers: {
                      Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                  },
              }
          );
          console.log('Response from Chat API:', response);

          // Extract response data
          const { answer, documents_used, internal_documents, external_documents } = response.data;

          // Add bot response to chat history
          const botMessage = {
              sender: 'bot',
              text: answer,
              documents_used: documents_used
  
          };
          const newChatHistory = [...updatedChatHistory, botMessage];
          setChatHistory(newChatHistory);

          // Update selected documents
          if (internal_documents && Array.isArray(internal_documents)) {
            const combinedDocuments = [...allSelectedDocuments, ...internal_documents, ...(external_documents || [])];
            setChatSelectedDocuments(combinedDocuments);
            setSelectedDocuments((prevSelected) => [...prevSelected, ...internal_documents, ...(external_documents || [])]);

            // Save updated documents to backend
            if (note?.id) {

                try {
                    await axios.patch(`/api/notes/${note.id}`, {
                        selected_documents: combinedDocuments,
                        last_edited: new Date().toISOString(),
                    });
                    console.log('Selected documents saved successfully.');
                } catch (error) {
                    console.error('Error saving selected documents:', error);
                }
            }
          }
          scrollToBottom();


          // Resummarize and update editor content
          const currentNoteContent = editor?.getHTML() || '';
          const resummarizationPayload = {
              current_note: currentNoteContent,
              bot_answer: answer,
              documents_used: documents_used.map(
                  (doc) => doc.title || doc.deka_name || doc.marthar_id || 'No Title'
              ),
          };
          const resummarizationResponse = await axios.post(
              '/api/resummarize',
              resummarizationPayload,
              {
                  headers: {
                      Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                  },
              }
          );

          const summarizedContent = resummarizationResponse.data.summarizedContent || 'No summary available';
          if (editor) {
              editor.commands.setContent(summarizedContent);
          }

          // Save note content to backend
          if (note?.id) {
              const payload = {
                  last_edited: new Date().toISOString(),
                  content: summarizedContent,
                  chat_history: newChatHistory,
              };
              await axios.patch(`/api/notes/${note.id}`, payload);
              console.log('Note updated successfully with summarized content.');
          }

          scrollToBottom(); // Ensure UI scrolls to bottom
          setIsNoteSidebarOpen(true);
      } catch (error) {
          console.error('Error during chat or re-summarization:', error);
      } finally {
          setIsLoading(false);
          setIsSummarizing(false);
      }
  }
};



// const handleWebSearch = async () => {
//   if (!message.trim()) {
//     console.error("Query is empty. Not sending request.");
//     return;
//   }

//   console.log("Sending query:", message);
//   const userMessage = { sender: "user", text: message };
//   const updatedChatHistory = [...chatHistory, userMessage];
//   setChatHistory(updatedChatHistory); // Update chat history locally
//   setMessage(""); // Clear input field
//   setIsLoading(true);
//   setIsSummarizing(true);

//   try {
//     // Prepare payload for web search API
//     const chatPayload = { query: message };

//     // Perform web search
//     const response = await axios.post("/api/web-search", chatPayload, {
//       headers: {
//         Authorization: `Bearer ${localStorage.getItem("authToken")}`,
//         "Content-Type": "application/json",
//       },
//     });

//     const { web_answer, web_reference } = response.data;
//     console.log("Output from web_reference References:", web_reference);
//     // Process web references
//     const referencesArray = Object.keys(web_reference || {})
//       .map((key) => {
//         const entry = web_reference[key] || {};
//         return {
//           title: entry.title || "No Title Available",
//           url: entry.url || "#",
//           content: entry.content || "No Content Available",
//         };
//       })
//       .filter((ref) => ref.url !== "#"); // Exclude invalid references

//     console.log("Parsed References:", referencesArray);

//     // Add bot response to chat history
//     const botMessage = { 
//       sender: "bot", 
//       text: web_answer, 
//       documents_used: referencesArray.map((ref) => ref.title || "Unknown Document"),
//     };
    
//     const newChatHistory = [...updatedChatHistory, botMessage];
//     setChatHistory(newChatHistory);
//     console.log("Documents used:", botMessage.documents_used);
//     console.log("Chat history:", newChatHistory);


//     // Save chat history to backend
//     if (note?.id) {
//       try {
//         await axios.patch(`/api/notes/${note.id}`, {
//           chat_history: newChatHistory,
//           last_edited: new Date().toISOString(),
//         });
//         console.log("Chat history saved successfully.");
//       } catch (error) {
//         console.error("Error saving chat history:", error.response?.data || error.message);
//       }
//     }
    

//     // Merge web references with previously selected documents
//     const mergedDocuments = [
//       ...selectedDocuments.filter(
//         (existingDoc) =>
//           !referencesArray.some((newDoc) => newDoc.url === existingDoc.url)
//       ),
//       ...referencesArray,
//     ];

//     console.log("Merged Documents:", mergedDocuments);

//     // Update state
//     setChatSelectedDocuments(mergedDocuments);
//     setSelectedDocuments(mergedDocuments);

//     // Persist updated selected documents to backend
//     await saveSelectedDocumentsToBackend(mergedDocuments);

//     // Prepare data for re-summarization
//     const currentNoteContent = editor?.getHTML() || "";
//     const resummarizationPayload = {
//       current_note: currentNoteContent,
//       bot_answer: web_answer,
//       documents_used: referencesArray.map((doc) => doc.title || "No Title"),
//     };

//     // Call resummarization API
//     const resummarizationResponse = await axios.post(
//       "/api/resummarize",
//       resummarizationPayload,
//       {
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem("authToken")}`,
//         },
//       }
//     );

//     // Extract and update summarized content
//     let summarizedContent =
//       resummarizationResponse.data.summarizedContent || "No summary available";
//     summarizedContent = formatMessage(summarizedContent);

//     if (editor) {
//       editor.commands.setContent(summarizedContent);
//     }

//     // Save updated note content to backend
//     if (note?.id) {
//       await axios.patch(`/api/notes/${note.id}`, {
//         content: summarizedContent,
//         last_edited: new Date().toISOString(),
//       });
//       console.log("Note content updated successfully.");
//     }

//     scrollToBottom(); // Scroll to the latest message
//     setIsNoteSidebarOpen(true); // Open note sidebar
//   } catch (error) {
//     console.error(
//       "Error during web search or resummarization:",
//       error.response?.data || error.message
//     );
//     setChatHistory((prev) => [
//       ...prev,
//       { sender: "bot", text: "Web search failed. Please try again." },
//     ]);
//   } finally {
//     setIsLoading(false);
//     setIsSummarizing(false);
//   }
// };


// The saveSelectedDocumentsToBackend function

const saveSelectedDocumentsToBackend = async (documents) => {
  try {
    await axios.patch(`/api/notes/${note.id}`, {
      selected_documents: documents,
      last_edited: new Date().toISOString(),
    });
    console.log('Selected documents saved successfully.');
  } catch (error) {
    console.error('Error saving selected documents:', error);
  }
};





// Allow press "Enter" to send message
const handleKeyDown = (e) => {
  if (e.key === "Enter") {
      e.preventDefault();
      handleSendMessage();
  }
};


  
  
  // Add more document click
  const handleAddClick = () => {
    setIsAddModalOpen(true); // Open the modal
  };
// Add more document close
  const handleCloseAddModal = () => {
    setIsAddModalOpen(false); // Close the modal
  };

  // upload user document click
  const handleUploadClick = () => {
    setIsUploadModalOpen(true);
  };

   // Helper function to validate file types
   const isValidFileType = (file) => {
    const validTypes = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/plain'];
    return validTypes.includes(file.type);
  };
  
  // Modified handleFileUpload to ensure modal closes after upload
const handleFileUpload = async (files) => {
  const validFiles = Array.from(files).filter(isValidFileType); // Filter valid files only
  
  if (validFiles.length === 0) {
    alert("Please upload valid file types: PDF, DOCX, or TXT");
    return;
  }

  setIsUploading(true);
  setUploadFiles([...uploadFiles, ...validFiles]); // Add new files to the list
  try {
    const formData = new FormData();
    Array.from(files).forEach((file) => formData.append('files', file));

    // API call to upload files
    await axios.post('/api/upload', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    // Close modal after upload is complete
    handleCloseAddModal();
  } catch (error) {
    console.error("Error uploading file:", error);
  } finally {
    setIsUploading(false); // End upload indicator
  }
};

  // Handle drag events
  const handleDragOver = (event) => {
    event.preventDefault();
    setDragActive(true);
  };

  const handleDragLeave = () => {
    setDragActive(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragActive(false);
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      handleFileUpload(event.dataTransfer.files);
    }
  };
  
  // Function to handle search query input
const handleSearchQueryChange = (e) => {
  setSearchQuery(e.target.value);
  if (e.target.value.trim() !== "") {
    setShowUploadSection(false); // Hide upload section when query is typed
  } else {
    setShowUploadSection(true); // Show upload section when query is empty
  }
};

// Function to reset search query and show upload section
const handleUploadButtonClick = () => {
  setShowUploadSection(true);
  setSearchQuery(""); // Clear search query when showing upload section
};






const handleSelectDocument = async (document) => {
  setSelectedDocuments((prevSelected) => {
    const isSelected = prevSelected.some((doc) => areDocumentsEqual(doc, document));
    const updatedDocuments = isSelected
      ? prevSelected.filter((doc) => !areDocumentsEqual(doc, document))
      : [...prevSelected, document];

    saveSelectedDocumentsToBackend(updatedDocuments); // Persist to backend
    return updatedDocuments;
  });

  // Keep chatSelectedDocuments synchronized
  setChatSelectedDocuments((prevChatSelected) => {
    const isChatSelected = prevChatSelected.some((doc) => areDocumentsEqual(doc, document));
    return isChatSelected
      ? prevChatSelected.filter((doc) => !areDocumentsEqual(doc, document))
      : [...prevChatSelected, document];
  });
};





// Updated Helper function to check document equality for both deka and marthar documents
const areDocumentsEqual = (docA, docB) => {
  return (
    (docA.deka_name && docA.deka_name === docB.deka_name) || 
    (docA.marthar_id && docA.marthar_id === docB.marthar_id)
  );
};

  const handleDocumentClick = (doc) => {
    setSelectedDoc(doc); // Set the selected document
    setIsSidebarOpen(true); // Open the sidebar with document details
  };

  // Handle the click on the trash icon to show confirmation
  const handleDeleteIconClick = (doc) => {
    setDocToDelete(doc);
    setIsConfirmingDelete(true);
  };

  // Handle delete confirmation
  const confirmDeleteDocument = async (doc) => {
    try {
      const updatedDocuments = selectedDocuments.filter(d => d !== doc);
      setSelectedDocuments(updatedDocuments);
  
      // PATCH request to update the note with the updated selected documents
      await axios.patch(`/api/notes/${note.id}`, {
        selected_documents: updatedDocuments,
        last_edited: new Date().toISOString(), // Keep last edited timestamp updated
      });
  
      console.log('Document deleted and note updated.');
    } catch (error) {
      console.error('Error deleting document:', error);
    } finally {
      setIsConfirmingDelete(false); // Close confirmation modal
    }
  };
  

// Cancel the deletion process
const cancelDelete = () => {
  setIsConfirmingDelete(false);
  setDocToDelete(null);
};

const handleChatWithSelected = async () => {
  if (selectedDocuments.length === 0) {
    console.error('No documents selected.');
    return;
  }

  // Combine selected and additional documents
  const combinedDocuments = [...additionalDocuments, ...selectedDocuments];
  const uniqueDocuments = Array.from(
    new Map(
      combinedDocuments.map((doc) => [
        doc.id || doc.pdf_path || doc.m_pdf_path,
        doc,
      ])
    ).values()
  );

  setAdditionalDocuments(uniqueDocuments);
  setIsAddModalOpen(false); // Close the upload modal

  try {
    setIsSummarizing(true); // Set loading state to true

    // Log the documents being sent for debugging
    console.log('Documents being sent for summarization:', uniqueDocuments);

    // 1. Send request to summarize documents
    const summarizationResponse = await axios.post('/api/summarize_doc', {
      query: 'Start summarization',
      selected_documents: uniqueDocuments, // Send the selected documents
    });

    // 2. Extract summarized content from the response
    const summarizedContent = summarizationResponse.data.summarize || 'No summary available';
    console.log('Summarization API Response:', summarizationResponse.data);

    // Update the state with the summarized content
    setSelectedDocSummary(summarizedContent);

    // 3. Update the chat editor with the summarized content
    if (editor) {
      const currentContent = editor.getHTML(); // Get current content in the editor
      const updatedContent = `${currentContent}<p>${summarizedContent}</p>`; // Append the summarized content
      editor.commands.setContent(updatedContent); // Set the new content in the editor

      // Wait for the editor to update before scrolling
      await new Promise((resolve) => setTimeout(resolve, 500));
      scrollToBottomEditor();

      // 4. Save the updated note content and documents to the backend
      const finalUpdatedContent = editor.getHTML(); // Get updated content
      if (note.id) {
        await axios.patch(`/api/notes/${note.id}`, {
          last_edited: new Date().toISOString(),
          content: finalUpdatedContent,
          selected_documents: uniqueDocuments, // Update selected documents as well
        });
        console.log('Note updated successfully with summarized content.');
      }
    } else {
      console.error('Editor is not initialized.');
    }
  } catch (error) {
    console.error('Error processing chat with selected documents:', error);
  } finally {
    setIsSummarizing(false); // Reset loading state
  }
};


  
  // handle step 2 sub-select document for chat-with-documents

  // Toggle select/deselect all documents
  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      setChatSelectedDocuments([...selectedDocuments]); // Select all documents
    } else {
      setChatSelectedDocuments([]); // Deselect all documents
    }
  };

  const handleDocumentCheckbox = (document) => {
    setChatSelectedDocuments((prevSelected) => {
      if (prevSelected.includes(document)) {
        // If already selected, remove it from the selection
        return prevSelected.filter((doc) => doc !== document);
      } else {
        // If not selected, add it to the selection
        return [...prevSelected, document];
      }
    });
  };



  const handleSearch = async (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      setIsSearchLoading(true);
      setIsAnswerLoading(true); // Start loading for the answer
      setAnswer(''); // Reset the answer initially
      
      try {
        const response = await axios.post(
          '/api/search',  // Ensure this is the correct API URL
          { query: searchQuery },
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('authToken')}`,  // Add Authorization header
            },
          }
        );
  
        const data = response.data;
  
        if (data.results && Array.isArray(data.results) && data.m_results && Array.isArray(data.m_results)) {
          const dekaResultsWithCheckbox = data.results.map(result => ({
            ...result,
            isChecked: false,
          }));
          const martharResultsWithCheckbox = data.m_results.map(result => ({
            ...result,
            isChecked: false,
          }));
     
          setSearchResults({ 
            dekaResults: dekaResultsWithCheckbox, 
            martharResults: martharResultsWithCheckbox 
          });
          setActiveTab('all');
        } else {
          console.error('Expected arrays of results but got:', data);
          setSearchResults({ dekaResults: [], martharResults: [] });
        }

        // Stop search loading after search completes
      setIsSearchLoading(false);

      // Begin fetching the answer from /api/answer
      const answerResponse = await axios.post(
        '/api/answer',
        { query: searchQuery },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`, // Add Authorization header
          },
        }
      );

      console.log(answerResponse.data); // Check the full structure of the response
      // Extract answer from the exact path, if it’s nested

      console.log(answerResponse.data); // Check the full structure of the response
      // Extract answer from the exact path, if it’s nested
      const extractedAnswer = answerResponse.data.answer || answerResponse.data.data?.answer || 'No answer available';
      setAnswer(extractedAnswer);
      // Ensure rag_results is an array; set to an empty array if undefined
      const relatedDocuments = Array.isArray(answerResponse.data.rag_results)
      ? answerResponse.data.rag_results
      : [];
    setRResults(relatedDocuments);
      
    } catch (error) {
      console.error('Error fetching results or answer:', error);
      setAnswer('Error fetching answer');
      setRResults('Error fetching documents'); // Update mResults with additional references
    } finally {
      // End answer loading only after answer is fully fetched
      setIsAnswerLoading(false);
    }
  }
};

  // Consolidated function to handle input changes for search and DekaSearch
  const handleInputChange = (e, field) => {
    const value = e.target.value;

    // Update the relevant state based on the field type
    if (field === 'query') {
      setSearchQuery(value);
    } else if (field === 'dekaId') {
      setDekaId(value);
    } else if (field === 'dekaYear') {
      setDekaYear(value);
    }

    // Determine whether to show or hide the upload section
    const shouldHideUploadSection = 
      value.trim() !== "" || searchQuery.trim() !== "" || dekaId.trim() !== "" || dekaYear.trim() !== "";
    
    setShowUploadSection(!shouldHideUploadSection);
  };



  // Function to handle Deka search
  const handleDekaSearch = async () => {
    if (!dekaId && !dekaYear) {
      console.error('Please enter either Deka ID or Deka Year for the search.');
      return;
    }

    setIsDekaSearchLoading(true);
    setActiveTab('all'); // Ensure the 'เอกสารทั้งหมด' tab is selected by default

    try {
      const response = await axios.post(
        '/api/key-search',
        { dekaId, dekaYear },
        { headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` } }
      );
      
      const results = response.data.results || [];
      setSearchResults({ dekaResults: results });
      setShowUploadSection(false); // Hide upload section while viewing results
    } catch (error) {
      console.error('Error fetching Deka search results:', error);
    } finally {
      setIsDekaSearchLoading(false);
    }
  };

  
      
  const handleCloseSidebar = () => {
    setIsSidebarOpen(false);
  };

  const toggleNoteSidebar = () => {
    setIsNoteSidebarOpen(!isNoteSidebarOpen);
  }; 






  return (
    <div className="flex h-screen relative">
      {/* Left Sidebar */}
      <div className="fixed top-0 left-0 h-screen w-72 bg-white border-r border-gray-200 p-4">

      <div className="flex items-center justify-between">
          {/* Back button aligned to the left */}
          <button 
            className="text-gray-700 hover:text-gray-900" 
            onClick={() => navigate(-1)} // Go back to the previous page
          >
            <ArrowLeft size={24} />
          </button>
          
          {/* KHORN text centered */}
          <h1 className="text-2xl font-bold text-center flex-1">KHORN</h1>

          {/* Placeholder to balance the layout */}
          <div style={{ width: '24px' }}></div> {/* Empty div to balance space */}
        </div>
        <br></br>
        <h2 className="text-lg font-semibold mb-4">เอกสารอ้างอิง</h2>

        {/* Select All Checkbox */}
      {/* <div className="flex items-center space-x-2 mb-4">
        <input type="checkbox" checked={selectAll} onChange={handleSelectAll} />
        <span>เลือกทั้งหมด</span>
      </div> */}


        {/* Render List of Selected Documents */}


        
      <DocumentList
        selectedDocuments={selectedDocuments}
        handleDocumentClick={handleDocumentClick}
        handleDeleteIconClick={handleDeleteIconClick}
      />
        <div>
          <button
            className="w-full bg-white-950 text-black p-2 rounded-3xl hover:bg-slate-950 hover:text-white transition-all duration-300 ease-in-out border border-black"
            onClick={handleAddClick}
          >
            ค้นหาเอกสารเพิ่มเติม
          </button>
        </div>
        {/* Delete Confirmation Popup */}
        {isConfirmingDelete && docToDelete && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded shadow-lg">
              <h3 className="text-xl font-bold mb-4">
                Deleting "{docToDelete.deka_name || docToDelete.marthar || 'this document'}"?
              </h3>
              <div className="flex justify-end">
                <button 
                  className="bg-red-500 text-white p-2 rounded mr-2"
                  onClick={() => confirmDeleteDocument(docToDelete)}
                >
                  Yes
                </button>
                <button 
                  className="bg-gray-300 p-2 rounded"
                  onClick={cancelDelete}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    

      {/* Main Chat Area */}
      <div className={`ml-72 h-full flex-1 flex flex-col transition-all duration-300 ${isNoteSidebarOpen ? 'ml-32' : ''} ${isNoteSidebarOpen ? 'mr-120' : ''}`}
      onScroll={handleChatScroll}>
            <button
      className="bg-slate-900 text-white p-2 rounded-lg z-50 fixed top-4 right-4"
      onClick={toggleNoteSidebar}
    >
      {isNoteSidebarOpen ? "ปิดการสรุป" : "ดูการสรุป"}
    </button>
      <div className="bg-transparent p-4 flex justify-between items-center">
          <h1 className="text-xl font-bold"><input
        type="text"
        value={noteTitle}
        onChange={(e) => setNoteTitle(e.target.value)}
        onBlur={async () => {
          if (note.id) {
            try {
              await axios.patch(
                `/api/notes/${note.id}`,
                {
                  title: noteTitle.trim(),
                  last_edited: new Date().toISOString(),
                },
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                  },
                }
              );
              
            } catch (error) {
              console.error('Error updating note title:', error);
            }
          } else {
            console.error('No note ID available for title update.');
          }
        }}
        placeholder="รายละเอียดวิจัยกฎหมาย"
        className="w-full p-2 rounded-lg mb-2 font-semibold bg-transparent"
      /></h1>
          
        </div>

        {/* Chat Messages Section */}
        <div className="flex-1 overflow-y-auto p-4 space-y-4 bg-gray-50">
  {/* Render the chat history */}
  {chatHistory.map((chat, index) => (
    <div
      key={index}
      className={`p-3 rounded-lg max-w-3/4 shadow-md ${
        chat.sender === 'user' ? 'bg-blue-100 ml-auto text-right' : 'bg-gray-100'
      }`}
    >
      <p
        className={chat.sender === 'user' ? 'text-blue-800' : 'text-gray-800'}
        dangerouslySetInnerHTML={{ __html: formatMessage(chat.text) }}
      ></p>
      {/* Copy Icon */}
      <button
        className="ml-2 text-gray-400 hover:text-gray-600"
        onClick={() => navigator.clipboard.writeText(chat.text)}
        title="Copy to clipboard"
      >
        <Copy size={16} />
      </button>

      {/* Display documents_used for bot messages */}
      {chat.documents_used && chat.sender === 'bot' && (
        <div className="mt-2 flex flex-wrap gap-2">
          <p className="p-2 rounded bg-gray-900 text-white">เอกสารอ้างอิงที่คาดว่าเกี่ยวข้อง</p>
          {chat.documents_used.map((doc, index) => (
            <div
            key={index}
            className={`p-2 rounded shadow-sm ${
              typeof doc === 'object' && doc.deka_name
                ? 'bg-blue-200 text-blue-900'
                : 'bg-gray-300 text-black'
            }`}
          >
            {typeof doc === 'string' ? doc : doc.deka_name || doc.title || 'Unnamed Document'}
          </div>
          
          
          ))}

        </div>
      )}

      {/* Display web_reference for bot messages */}
      {chat.references && chat.sender === 'bot' && (
  <div className="mt-2 flex flex-wrap gap-2">
    <p className="p-2 rounded bg-gray-300">แหล่งข้อมูลจากเว็บ</p>
    {chat.references.map((ref, idx) => (
      <a
        key={idx}
        href={ref.url}
        target="_blank"
        rel="noopener noreferrer"
        className="p-2 rounded bg-blue-200 text-blue-900 shadow-sm hover:underline"
      >
        {ref.title}
      </a>
    ))}
  </div>
)}



      <div ref={chatEndRef} />
    </div>
  ))}

  {/* Loading indicator */}
  {isLoading && (
    <div className="p-3 text-center">
      <div className="skeleton h-8 bg-gray-300 rounded w-full max-w-3/4 mx-auto"></div>
      <div className="skeleton h-8 bg-gray-300 rounded w-full max-w-3/4 mx-auto"></div>
      <div className="skeleton h-8 bg-gray-300 rounded w-full max-w-3/4 mx-auto"></div>
    </div>
  )}
</div>


        {/* Scroll to Bottom Button (placed above the input field) */}
  {showScrollButton && (
    <button
      onClick={scrollToBottom}
      className="bg-blue-500 text-white p-2 rounded-full shadow-lg fixed"
      style={{
        marginBottom: '20px', // Space above the chat input field
        alignSelf: 'center', // Center the button above the input
        zIndex: 1000,
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        cursor: 'pointer',
      }}
    >
      ↓
    </button>
  )}

        {/* Input Field for User Messages */}
        <div className="bg-gray-50 p-4 flex items-center space-x-2">

        <input
            type="text"
            placeholder= "เริ่มต้นวิจัยกฎหมายตรงนี้ได้เลย"
            className="w-full p-2 pl-8 pr-4 border rounded-full focus:ring-2 focus:ring-blue-500"
            value={message}
            onChange={(e) => setMessage(e.target.value)}  // Update `message`
            onKeyDown={handleKeyDown}  // Handle Enter key
        />

          {/* Send Button */}
          <button
            className={`ml-2 flex items-center justify-center w-10 h-10 rounded-full transition ${
              message.trim() ? 'bg-blue-500 text-white hover:bg-blue-600' : 'bg-gray-300 text-gray-500'
            }`}
            onClick={handleSendMessage} // Change behavior based on mode
            disabled={!message.trim()}
          >
            <ArrowUp className="w-5 h-5" />
          </button>

        </div>
      </div>


      {isAddModalOpen && (
      <div className="fixed inset-0 z-50 bg-black bg-opacity-30 flex justify-center items-center">
        <div className="bg-white w-11/12 max-w-4xl p-8 rounded-lg h-3/4 overflow-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">ค้นหาเอกสาร</h2>
          <button onClick={handleCloseAddModal}>
            <X size={20} />
          </button>
        </div>
  
        {/* Search Input */}
        <form onSubmit={handleSearch} className="w-full max-w-6xl mb-4">
        <div className="relative">
          <input
            type="text"
            value={searchQuery}
            onChange={handleSearchQueryChange} // Update to track search query
            placeholder="พิมพ์เนื่อเรื่องเพื่อค้นหา..."
            className="w-full p-4 pr-12 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        <button type="submit" className="absolute right-4 top-1/2 transform -translate-y-1/2">
          <Search className="text-gray-400" />
        </button>
      </div>
      <div className="flex items-center gap-2 mt-4">
      <p className="whitespace-nowrap">ค้นหาด้วยเลขฎีกา</p>
    <input
      type="text"
      value={dekaId}
      onChange={(e) => handleInputChange(e, 'dekaId')}
      placeholder="เลขฎีกา"
      className="w-1/3 p-2 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
    />
    <input
      type="text"
      value={dekaYear}
      onChange={(e) => handleInputChange(e, 'dekaYear')}
      placeholder="ปีฎีกา"
      className="w-1/3 p-2 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
    />
    <button
      onClick={handleDekaSearch}
      className="px-4 py-2 bg-blue-500 text-white rounded flex items-center"
      disabled={isDekaSearchLoading}
    >
      {isDekaSearchLoading ? (
        'กำลังค้นหา...') : (
        'ค้นหา'
      )}
    </button>
  </div>

{/* Skeleton Loader */}
{isDekaSearchLoading && (
  <div className="w-full max-w-6xl p-4 bg-gray-200 rounded-lg mb-4">
    <div className="skeleton-block skeleton h-8 bg-gray-300 mb-2"></div>
    <div className="skeleton-block skeleton h-8 bg-gray-300 mb-2"></div>
    <div className="skeleton-block skeleton h-8 bg-gray-300"></div>
  </div>
)}




{/* Conditionally Render Upload Section */}
{showUploadSection && (
  <div>
    {/* Upload Document Section */}
    <div className="flex flex-col items-center mt-6">
      {/* Upload button and other upload-related elements here */}
    </div>
  </div>
)}


    </form>
  


{/* Answer Block */}
{!isAnswerLoading && answer && (
  <div className="w-full max-w-6xl p-4 bg-gray-200 rounded-lg mb-4">
    <h2 className="text-xl font-semibold mb-2">Answer</h2>
    <p>{answer}</p>
  </div>
)}

{isAnswerLoading && (
  <div className="flex justify-center w-full mb-4">
    <button
      type="button"
      className="inline-flex items-center px-6 py-3 font-semibold leading-6 text-sm shadow rounded-md text-white bg-blue-600 hover:bg-indigo-400 transition ease-in-out duration-150 cursor-not-allowed"
      disabled
    >
      <svg
        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
      Answering...
    </button>
  </div>
)}

{/* Additional References Block */}
{!isAnswerLoading && Array.isArray(rResults) && rResults.length > 0 && (
  <div className="w-full max-w-6xl p-4 rounded-lg mb-4">
    <div className="flex justify-between items-center mb-2">
      <h2 className="text-xl font-semibold mb-2">มาตรากฎหมายอ้างอิง</h2>
      {selectedDocuments.length > 0 && (
        <div className="flex justify-end">
        </div>
      )}
    </div>
    <ul>
      {rResults.map((doc, index) => (
        <li
          key={index}
          className={`p-2 border mb-2 hover:bg-gray-100 rounded cursor-pointer relative ${
            selectedDocuments.some((selectedDoc) => areDocumentsEqual(selectedDoc, doc))
              ? 'bg-blue-100'
              : ''
          }`}
          onClick={() => handleSelectDocument(doc)}
        >
          <div className="flex justify-between items-start">
            <div>
              <h4 className="font-bold">
                {doc.marthar || 'No Title Available'}
                <span
                  className={`p-1 rounded text-xs ml-2 ${
                    selectedDocuments.some((selectedDoc) => areDocumentsEqual(selectedDoc, doc))
                      ? 'bg-blue-500 text-white'
                      : 'bg-gray-300 text-gray-700'
                  }`}
                >
                  {selectedDocuments.some((selectedDoc) => areDocumentsEqual(selectedDoc, doc))
                    ? 'Selected'
                    : 'Not Selected'}
                </span>
              </h4>
              <p className="text-xs text-gray-600 mb-3">
                {doc.document_name || 'No Description Available'}
              </p>
              <p className="text-xs text-gray-600">
                {doc.info || 'No Description Available'}
              </p>
            </div>
          </div>
        </li>
      ))}
    </ul>
  </div>
)}


      {/* Conditionally show tabs only if search results are available */}
      {searchResults.dekaResults?.length > 0 || searchResults.martharResults?.length > 0 ? (
        <div className="flex justify-between items-center mb-4">
          <div className="flex space-x-4">
          <ul className="flex border-b">
            <li
              className={`p-2 cursor-pointer ${activeTab === 'all' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
              onClick={() => setActiveTab('all')}
            >
              เอกสารทั้งหมด
            </li>
            <li
              className={`p-2 cursor-pointer ${activeTab === 'deka' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
              onClick={() => setActiveTab('deka')}
            >
              คำพิพากษาฎีกา
            </li>
            <li
              className={`p-2 cursor-pointer ${activeTab === 'marthar' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
              onClick={() => setActiveTab('marthar')}
            >
              มาตรากฎหมาย
            </li>
          </ul>
          </div>
          
          {/* Button for proceeding to chat with selected documents */}
          {selectedDocuments.length > 0 && (
            <div className="flex justify-end">
            </div>
          )}
        </div>
      ) : null}
      {isSearchLoading ? (
        <div className="w-full max-w-6xl p-4 bg-gray-200 rounded-lg mb-4">
          <div className="skeleton-block skeleton h-8 bg-gray-300"></div>
          <div className="skeleton-block skeleton h-8 bg-gray-300"></div>
          <div className="skeleton-block skeleton h-8 bg-gray-300"></div>
        </div>
      ) : (
        <ul className="space-y-2">
          {/* Conditionally render the list based on the active tab */}
          {activeTab === 'all' && (
          [...(searchResults.martharResults || []), ...(searchResults.dekaResults || [])].map((result) => (
            <li
              key={result.pdf_path || result.m_pdf_path}
              className={`p-2 border rounded hover:bg-gray-100 cursor-pointer relative ${
                selectedDocuments.some((selectedDoc) => areDocumentsEqual(selectedDoc, result)) ? 'bg-blue-100' : ''
              }`}
              onClick={() => handleSelectDocument(result)}
            >
              <div className="flex justify-between items-start">
                <div>
                  <h4 className="font-bold">
                    {result.deka_name || `${result.marthar} - ${result.document_name}` || 'No Title Available'}
                    <span
                      className={`p-1 rounded text-xs ${
                        selectedDocuments.some((selectedDoc) => areDocumentsEqual(selectedDoc, result))
                          ? 'bg-blue-500 text-white'
                          : 'bg-gray-300 text-gray-700'
                      }`}
                    >
                      {selectedDocuments.some((selectedDoc) => areDocumentsEqual(selectedDoc, result)) ? 'เลือกเอกสาร' : 'ไม่ถูกเลือก'}
                    </span>
                  </h4>
                  <p className="text-sm text-gray-600">{result.short_deka || result.info || 'No Description Available'}</p>
                </div>
              </div>
            </li>
          ))
        )}
          {activeTab === 'deka' && (
          searchResults.dekaResults?.map((result) => (
            <li
              key={result.pdf_path}
              className={`p-2 border rounded hover:bg-gray-100 cursor-pointer relative ${
                selectedDocuments.some((selectedDoc) => areDocumentsEqual(selectedDoc, result)) ? 'bg-blue-100' : ''
              }`}
              onClick={() => handleSelectDocument(result)}
            >
              <div className="flex justify-between items-start">
                <div>
                  <h4 className="font-bold">
                    {result.deka_name || result.marthar || 'No Title Available'}
                    <span
                      className={`p-1 rounded text-xs ${
                        selectedDocuments.some((selectedDoc) => areDocumentsEqual(selectedDoc, result))
                          ? 'bg-blue-500 text-white'
                          : 'bg-gray-300 text-gray-700'
                      }`}
                    >
                      {selectedDocuments.some((selectedDoc) => areDocumentsEqual(selectedDoc, result)) ? 'Selected' : 'Not Selected'}
                    </span>
                  </h4>
                  <p className="text-sm text-gray-600">{result.short_deka || result.info || 'No Description Available'}</p>
                </div>
              </div>
            </li>
          ))
        )}
          {activeTab === 'marthar' && (
          searchResults.martharResults?.map((result) => (
            <li
              key={result.m_pdf_path}
              className={`p-2 border rounded hover:bg-gray-100 cursor-pointer relative ${
                selectedDocuments.some((selectedDoc) => areDocumentsEqual(selectedDoc, result)) ? 'bg-blue-100' : ''
              }`}
              onClick={() => handleSelectDocument(result)}
            >
              <div className="flex justify-between items-start">
                <div>
                  <h4 className="font-bold">
                    {`${result.marthar} - ${result.document_name}` || 'No Title Available'}
                    <span
                      className={`p-1 rounded text-xs ${
                        selectedDocuments.some((selectedDoc) => areDocumentsEqual(selectedDoc, result))
                          ? 'bg-blue-500 text-white'
                          : 'bg-gray-300 text-gray-700'
                      }`}
                    >
                      {selectedDocuments.some((selectedDoc) => areDocumentsEqual(selectedDoc, result)) ? 'Selected' : 'Not Selected'}
                    </span>
                  </h4>
                  <p className="text-sm text-gray-600">{result.info || 'No Description Available'}</p>
                </div>
              </div>
            </li>
          ))
        )}
      </ul>
    )}
    </div>
  </div>
)}



      {/* Sliding Sidebar for Document Details */}
      <div
        className={`fixed left-0 top-0 h-full w-[40rem] bg-white border-r border-gray-200 p-4 shadow-lg transition-transform duration-300 ${
          isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
        }`}
      >
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-bold">รายละเอียดเอกสาร</h2>
          <button onClick={handleCloseSidebar}>
            <X size={20} />
          </button>
        </div>

        {selectedDoc ? (
        <div>
        {selectedDoc.pdf_path ? ( // This means it's a Deka document
        <div>
          <h2 className="text-xl font-semibold mb-2">{selectedDoc.deka_name || 'No Title Available'}</h2>
            {/* <p><strong>ปี:</strong> {selectedDoc.year || 'N/A'}</p> */}
            <p><strong>กฎหมายที่เกี่ยวข้อง:</strong> {selectedDoc.related_law || 'N/A'}</p>
        {/* Scrollable content section */}
          <p className="mt-4"> <strong>ย่อสั้น:</strong></p>
          <div className="mt-4 overflow-y-auto max-h-48 p-2 border rounded bg-white-900">
            <p> {selectedDoc.short_deka || 'Full content not available.'}</p>
          </div>
          <p className="mt-4"> <strong>ย่อยาว:</strong></p>
          <div className="mt-4 overflow-y-auto max-h-96 p-2 border rounded bg-white-900">
            <p dangerouslySetInnerHTML={{ __html: cleanText(selectedDoc.full_deka) || 'Full content not available.'}}></p>
          </div>
        </div>
        ) : selectedDoc.m_pdf_path ? ( // This means it's a Marthar document
          <div>
            {/* Use dangerouslySetInnerHTML to display cleaned HTML */}
            <h2 className="text-xl font-semibold mb-2">{selectedDoc.marthar || 'No Title Available'}</h2>
            <strong>เอกสาร:</strong> 
            <h3 className="text-xl font-semibold mb-2">{selectedDoc.document_name || 'No Title Available'}</h3>
              <strong>รายละเอียด:</strong> 
              <p dangerouslySetInnerHTML={{ __html: cleanText(selectedDoc.info) || 'No Title Available'}}></p>
              <p><strong>หมายเหตุ:</strong> {selectedDoc.note_or_edit || 'N/A'}</p>
          </div>
          ) : selectedDoc.url ? ( // This means it's a Web Reference
            <div>
              <h2 className="text-xl font-semibold mb-2">{selectedDoc.title || 'No Title Available'}</h2>
              <strong>รายละเอียด:</strong> 
              <p className="text-gray-700 mb-4">
              {selectedDoc.content || 'No Title Available'}
              </p>
              <a
                href={selectedDoc.url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:underline"
              >
                {selectedDoc.url}
              </a>
            </div>
          ) : (
            <p>No document details available.</p>
          )}
        </div>
      ) : (
        <p>Select a document to view its details.</p>
      )}
    </div>



      {/* Note-Taking Sidebar */}
      <div className={`fixed right-0 top-0 h-full w-[30rem] bg-white border-l border-gray-200 p-4 transition-transform duration-300 ${
        isNoteSidebarOpen ? 'translate-x-0' : 'translate-x-full'
      }`}>
      <input
        type="text"
        value={noteTitle}
        onChange={(e) => setNoteTitle(e.target.value)}
        onBlur={async () => {
          if (note.id) {
            try {
              await axios.patch(
                `/api/notes/${note.id}`,
                {
                  title: noteTitle.trim(),
                  last_edited: new Date().toISOString(),
                },
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                  },
                }
              );
              
            } catch (error) {
              console.error('Error updating note title:', error);
            }
          } else {
            console.error('No note ID available for title update.');
          }
        }}
        placeholder="รายละเอียดวิจัยกฎหมาย"
        className="w-full p-2 rounded-lg mb-2 font-semibold"
      />

      {/* Main Editor */}
      <div
  ref={editorContentRef}
  className="note-editor border border-white rounded-lg mb-4 p-4 min-h-[650px] max-h-[650px] overflow-y-auto"
  onClick={() => editor?.commands.focus()}  // Focus the editor when clicked
>
  {isSummarizing ? (
    <div className="skeleton-loading">
      {/* Loading skeleton */}
      <div className="skeleton h-4 w-full mb-4"></div>
      <div className="skeleton h-4 w-full mb-4"></div>
      <div className="skeleton h-4 w-3/4 mb-4"></div>
      <div className="skeleton h-4 w-full mb-4"></div>
      <div className="skeleton h-4 w-full mb-4"></div>
      <div className="skeleton h-4 w-2/4 mb-4"></div>
      <div className="skeleton h-4 w-full mb-4"></div>
      <div className="skeleton h-4 w-full mb-4"></div>
      <div className="skeleton h-4 w-full mb-4"></div>
      <div className="skeleton h-4 w-3/4 mb-4"></div>
      <div className="skeleton h-4 w-3/4 mb-4"></div>
      <div className="skeleton h-4 w-2/4 mb-4"></div>
      <div className="skeleton h-4 w-full mb-4"></div>
      <div className="skeleton h-4 w-full mb-4"></div>
      <div className="skeleton h-4 w-2/4 mb-4"></div>
      <div className="skeleton h-4 w-full mb-4"></div>
      <div className="skeleton h-4 w-full mb-4"></div>
      <div className="skeleton h-4 w-2/4 mb-4"></div>
      <div className="skeleton h-4 w-full mb-4"></div>
      <div className="skeleton h-4 w-full mb-4"></div>
      <div className="skeleton h-4 w-full mb-4"></div>
      <div className="skeleton h-4 w-2/4 mb-4"></div>
    </div>
  ) : (
    editor ? <EditorContent editor={editor} /> : <p>Loading editor...</p>
  )}
</div>






            {/* Popup Toolbar */}
            {isPopupVisible && (
        <div
          ref={popupRef}
          className="absolute bg-white p-2 shadow-lg border rounded-md flex space-x-2"
          style={{ top: `${popupPosition.top}px`, left: `${popupPosition.left}px` }}
        >
          <button className="p-2 border rounded hover:bg-gray-100" onClick={() => applyFormatting('bold')}>
            <Bold size={16} />
          </button>
          <button className="p-2 border rounded hover:bg-gray-100" onClick={() => applyFormatting('italic')}>
            <Italic size={16} />
          </button>
          <button className="p-2 border rounded hover:bg-gray-100" onClick={() => applyFormatting('bulletList')}>
            <List size={16} />
          </button>
          <button className="p-2 border rounded hover:bg-gray-100" onClick={() => applyFormatting('orderedList')}>
            <ListOrdered size={16} />
          </button>
          <button className="p-2 border rounded hover:bg-gray-100" onClick={() => applyFormatting('link')}>
            <Link size={16} />
          </button>
          <button className="p-2 border rounded hover:bg-gray-100" onClick={() => applyFormatting('strike')}>
            <Strikethrough size={16} />
          </button>
          <button className="p-2 border rounded hover:bg-gray-100" onClick={() => applyFormatting('horizontal rule')}>
            <Minus size={16} />
          </button>
          <button className="p-2 border rounded hover:bg-gray-100" onClick={() => applyFormatting('h1')}>
            <Heading1 size={16} />
          </button>
          <button className="p-2 border rounded hover:bg-gray-100" onClick={() => applyFormatting('clear')}>
            <RemoveFormatting size={16} />
          </button>
        </div>
      )}

          </div>



    {/* end div */}
    </div>
  );
};

export default NoteEditor;