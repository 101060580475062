import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "./axiosConfig";

const plans = {
  free: {
    name: "Free",
    features: ["1 User", "KHORN (limited)", "Basic Support"],
    price: "Free",
  },
  pro: {
    name: "Pro",
    features: ["1 User", "Unlimited Projects", "KHORN Search", "KHORN Research Assistant", "24/7 Support"],
    monthlyPrice: 899,
    yearlyPrice: 5388,
    yearlyDiscount: 50, // Discount in percentage
  },
};

export default function ChangePlanPage() {
  const navigate = useNavigate();
  const [currentPlan, setCurrentPlan] = useState({ accountType: "", subscriptionCommitment: "" });
  const [currentFeatures, setCurrentFeatures] = useState([]);

  useEffect(() => {
    const fetchCurrentPlan = async () => {
      try {
        const token = localStorage.getItem("authToken");
        if (!token) {
          throw new Error("Authentication token not found.");
        }
    
        const response = await axios.get("/api/account", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
    
        const accountType = response.data.account_type
          ? response.data.account_type.toLowerCase()
          : "free"; // Default to "free" if account_type is missing or null
        const subscriptionCommitment = response.data.subscription_commitment
          ? response.data.subscription_commitment.toLowerCase()
          : "monthly"; // Default to "monthly" if subscription_commitment is missing or null
    
        setCurrentPlan({
          accountType,
          subscriptionCommitment,
        });
        setCurrentFeatures(plans[accountType]?.features || []);
      } catch (error) {
        console.error("Error fetching current plan:", error);
      }
    };
    

    fetchCurrentPlan();
  }, []);

  const handlePlanChange = (newPlanType, duration) => {
    if (!plans[newPlanType] || !["monthly", "yearly"].includes(duration)) {
      console.error("Invalid plan or duration:", newPlanType, duration);
      return;
    }

    const newPlan = {
      ...plans[newPlanType],
      accountType: newPlanType,
      subscriptionCommitment: duration,
    };

    const detailedCurrentPlan = {
      ...currentPlan,
      ...plans[currentPlan.accountType],
    };

    navigate("/change-plan-confirm", {
      state: {
        currentPlan: detailedCurrentPlan,
        newPlan,
      },
    });
  };

  const formatYearlyPrice = (plan) => {
    const monthlyEquivalent = (plan.yearlyPrice / 12).toFixed(0);
    return (
      <>
        Yearly: {plan.yearlyPrice.toLocaleString()} THB{" "}
        <span style={{ fontSize: "0.85em", color: "#555" }}>
          ({monthlyEquivalent} THB/mo,{" "}
          <span style={{ color: "green", fontWeight: "bold" }}>
            save {plan.yearlyDiscount}%
          </span>
          )
        </span>
      </>
    );
  };

  return (
    <div className="flex flex-col items-center bg-gray-50 min-h-screen py-8 px-4">
      <div className="max-w-6xl w-full">
        <h1 className="text-3xl font-bold text-gray-800 mb-8 text-center">Change Your Plan</h1>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Free Plan Card */}
          <div className="bg-gray-100 border border-gray-500 rounded-lg p-6 shadow-lg">
            <h2 className="text-xl font-semibold text-gray-700 mb-4">{plans.free.name} Plan</h2>
            <ul style={{ listStyleType: "none", paddingLeft: "0", marginTop: "10px" }}>
              {plans.free.features.map((feature, index) => (
                <li key={index} className="text-gray-700 mb-2">
                  {feature}
                </li>
              ))}
            </ul>
            <p className="mt-4 font-bold text-gray-900">Pricing: {plans.free.price}</p>
            {currentPlan.accountType?.toLowerCase() === "free" ? (
              <button className="w-full mt-4 px-4 py-2 bg-gray-400 text-white rounded-md cursor-not-allowed">
                Your Current Plan
              </button>
            ) : (
              <button
                className="w-full mt-4 px-4 py-2 bg-slate-900 text-white rounded-md hover:bg-red-700"
                onClick={() => handlePlanChange("free", "monthly")}
              >
                Downgrade to Free Plan
              </button>
            )}

          </div>

          {/* Pro Plan Card */}
          <div className="bg-green-100 border border-black rounded-lg p-6 shadow-lg">
            <h2 className="text-xl font-semibold text-green-700 mb-4">{plans.pro.name} Plan</h2>
            <ul style={{ listStyleType: "none", paddingLeft: "0", marginTop: "10px" }}>
              {plans.pro.features.map((feature, index) => (
                <li key={index} className="text-green-700 mb-2 font-bold">
                  {feature}
                </li>
              ))}
            </ul>
            <p className="mt-4 font-bold text-black">Pricing:</p>
            <p>Monthly: {plans.pro.monthlyPrice.toLocaleString()} THB</p>
            <p>{formatYearlyPrice(plans.pro)}</p>
            {currentPlan.accountType === "pro" && currentPlan.subscriptionCommitment === "monthly" ? (
              <button className="w-full mt-4 px-4 py-2 bg-gray-400 text-white rounded-md cursor-not-allowed">
                Your Current Plan (Monthly)
              </button>
            ) : (
              <button
                className="w-full mt-4 px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700"
                onClick={() => handlePlanChange("pro", "monthly")}
              >
                {currentPlan.subscriptionCommitment === "yearly" ? "Pro (Monthly)" : "Upgrade to Pro (Monthly)"}
              </button>
            )}
            {currentPlan.accountType === "pro" && currentPlan.subscriptionCommitment === "yearly" ? (
              <button className="w-full mt-4 px-4 py-2 bg-gray-400 text-white rounded-md cursor-not-allowed">
                Your Current Plan (Yearly)
              </button>
            ) : (
              <button
                className="w-full mt-4 px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700"
                onClick={() => handlePlanChange("pro", "yearly")}
              >
                {currentPlan.subscriptionCommitment === "monthly" ? "Pro (Yearly)" : "Upgrade to Pro (Yearly)"}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
