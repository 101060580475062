import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from './axiosConfig';
import './DocSearch.css';

const CommunityInsights = ({ dekaName, comments, onActionClick }) => {
    const [newComment, setNewComment] = useState(""); // Input for new comment
    const [likes, setLikes] = useState({}); // Track likes for each comment

    const handleAddComment = async () => {
        // Check if the user is authenticated
        if (!localStorage.getItem('authToken')) {
            // Trigger the modal to show login/signup prompt
            if (onActionClick) onActionClick();
            return; // Exit early if the user is not authenticated
        }
    
        try {
            const encodedDekaName = encodeURIComponent(`${dekaName.replace('/', '_').replace('deka_','')}`);
            console.log(`Encoded dekaName: ${encodedDekaName}`);  // Debugging statement

            const response = await axios.post(`/api/documents/${encodedDekaName}/comments`, { 
                userId: "UserId", // Replace with actual user ID if available
                text: newComment, 
                timestamp: new Date().toISOString() 
            }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` } // Include token here
            });

            // Add the new comment to the existing comments state
            comments.push(response.data);  // Assuming response contains the added comment
            setNewComment("");  // Clear input
        } catch (error) {
            console.error("Error adding comment:", error);
        }
    };


// Handle "Like" button click
const handleLike = (index) => {
    setLikes((prevLikes) => ({
        ...prevLikes,
        [index]: (prevLikes[index] || 0) + 1,
    }));
};

const formatTimestamp = (timestamp) => {
    const now = new Date();
    const date = new Date(timestamp);

    const diffInMs = now - date;
    const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
    const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
    const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

    if (diffInMinutes < 60) {
        // Within an hour, show as minutes
        return `${diffInMinutes} min${diffInMinutes !== 1 ? 's' : ''} ago`;
    } else if (diffInHours < 24) {
        // Within a day, show as hours
        return `${diffInHours} h${diffInHours !== 1 ? '' : ''} ago`;
    } else {
        // More than a day ago, show full date with GMT+7
        const hours = date.getUTCHours() + 7; // Adjust to GMT+7
        const minutes = date.getUTCMinutes();
        const day = date.getUTCDate();
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getUTCFullYear();

        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12;
        const formattedMinutes = minutes.toString().padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}${ampm} ${day} ${month} ${year} (GMT+7)`;
    }
};


return (
    <div className="w-1/4 ml-4 flex flex-col text-sm text-gray-600">
      <h4 className="text-xl font-semibold mb-2 text-gray-800">Community Insights</h4>
      <div className="overflow-y-auto h-80">
        {comments.length > 0 ? (
            comments.map((comment, index) => (
                <div key={index} className="mb-2">
                   {/* UserId and Timestamp on the same row */}
                <div className="flex items-center justify-between">
                    <p className="font-semibold text-gray-700">{comment.userId || "Anonymous"}</p>
                    <p className="text-xs text-gray-500">{formatTimestamp(comment.timestamp)}</p>
                </div>
                <p>{comment.text}</p>
                    <div className="flex items-center mt-1 space-x-2">
                        {/* <button
                            className="text-blue-500 hover:underline"
                            onClick={() => handleLike(index)}
                        >
                            Like ({likes[index] || 0})
                        </button>
                        <button className="text-blue-500 hover:underline">Reply</button> */}
                    </div>
                </div>
            ))
        ) : (
            <p>No comments available.</p>
        )}
      </div>
      <textarea
        value={newComment}
        onChange={(e) => setNewComment(e.target.value)}
        placeholder="Add your insight..."
        className="mt-2 p-2 border rounded-xl h-11 w-full focus:outline-none focus:ring-1 focus:ring-blue-500"
        rows="3"
      ></textarea>
      <button onClick={handleAddComment} className="mt-2 px-3 py-1 text-white bg-blue-500 rounded">
        Comment
      </button>
    </div>
);
};

const DocumentPage = () => {
  const { id } = useParams(); // Get document ID from the URL
  const [document, setDocument] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [showMoreDetails, setShowMoreDetails] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem('authToken')
  );

// Automatically set showMoreDetails if the user is authenticated
useEffect(() => {
    if (isAuthenticated) {
      setShowMoreDetails(true);
    }
  }, [isAuthenticated]);

  const handleActionClick = () => {
    if (!isAuthenticated) {
      setShowAuthModal(true);
    } else {
      // Handle the action (e.g., commenting or liking)
    }
  };

  const formatMessage = (text) => {
    return text
      .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
      .replace(/\n{2,}/g, '<br /><br />')
      .replace(/\n/g, '<br />');
  };

  useEffect(() => {
    const fetchDocument = async () => {
      try {
        const response = await axios.get(`/api/documents/${id}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
        });
        setDocument(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching document:', error);
        setIsLoading(false);
      }
    };

    fetchDocument();
  }, [id]);

  if (isLoading) {
    return <p>Loading document...</p>;
  }

  if (!document) {
    return <p>Document not found</p>;
  }

  return (
    <div className="mx-20">
      <div className="px-8 py-4 flex"> {/* Add flex and space between children */}
        {/* Main content */}
        <div className="flex-1 p-4 border rounded-xl bg-white shadow-sm">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-xl font-semibold">
              {document.deka_name}
            </h3>
          </div>

          <p><strong>{document.deka_name || 'ไม่ระบุ'}</strong> </p>
          <p className="text-l font-semibold mb-2">ย่อสั้น</p>
          <div
            dangerouslySetInnerHTML={{ __html: formatMessage(document.short_deka || 'ไม่ระบุ') }}
            className="text-gray-700 mb-4"
          />

          {/* "See More" button and conditional rendering */}
          {!showMoreDetails && (
            <button
              onClick={handleActionClick}
              className="px-3 py-1 text-white bg-blue-500 rounded-xl mb-4"
            >
              ดูตัวเต็ม
            </button>
          )}

          {showMoreDetails && (
            <>
              {document.full_deka && (
                <>
                  <p className="text-l font-semibold mb-2">ย่อยาว</p>
                  <div
                    dangerouslySetInnerHTML={{ __html: formatMessage(document.full_deka) }}
                    className="text-gray-700 mb-4"
                  />
                </>
              )}

              <div className="grid grid-cols-2 gap-4 mb-4">
                <div>
                  <strong>กฎหมายที่เกี่ยวข้อง:</strong>
                  <p className="text-gray-600">{document.related_law || 'ไม่ระบุ'}</p>
                </div>
                <div>
                  <strong>แหล่งที่มา:</strong>
                  <p className="text-gray-600">{document.source || 'ไม่ระบุ'}</p>
                </div>
                <div>
                  <strong>ชื่อคู่ความ:</strong>
                  <p className="text-gray-600">{document.party_name || 'ไม่ระบุ'}</p>
                </div>
                <div>
                  <strong>แผนก:</strong>
                  <p className="text-gray-600">{document.department || 'ไม่ระบุ'}</p>
                </div>
                <div>
                  <strong>ชื่อองค์คณะ:</strong>
                  <p className="text-gray-600">{document.current_judge || 'ไม่ระบุ'}</p>
                </div>
                <div>
                  <strong>หมายเลขคดีดำ ของศาลฎีกา:</strong>
                  <p className="text-gray-600">{document.case_number || 'ไม่ระบุ'}</p>
                </div>
                <div>
                  <strong>ศาลชั้นต้นและศาลอุทธรณ์:</strong>
                  <p className="text-gray-600">{document.previous_judge || 'ไม่ระบุ'}</p>
                </div>
                <div>
                  <strong>หมายเลขคดีดำ และ หมายเลขคดีแดง ของศาลชั้นต้น:</strong>
                  <p className="text-gray-600">{document.initial_case_numbers || 'ไม่ระบุ'}</p>
                </div>
              </div>
            </>
          )}
        </div>

         

        {/* Right side: Community Insights */}
        <CommunityInsights
          comments={document.comments || []}
          dekaName={document.deka_name}
          onActionClick={handleActionClick} // Pass the function as a prop
        />

        {/* Authentication Modal */}
        {showAuthModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 m-0 p-0">
            <div className="bg-white p-6 rounded-xl shadow-lg">
            <h2 className="text-xl font-semibold mb-4">เข้าใช้งาน</h2>
            <p>รบกวนล็อคอินเข้าใช้งานก่อนนะคะ</p>
            <div className="mt-4 flex justify-center space-x-2">
                <button
                onClick={() => setShowAuthModal(false)}
                className="px-4 py-2 bg-gray-500 text-white rounded-xl mr-2"
                >
                ปิด
                </button>
                <button
                onClick={() => {
                    window.location.href = '/auth';
                }}
                className="px-4 py-2 bg-blue-500 text-white rounded-xl"
                >
                เข้าสู่ระบบ
                </button>
            </div>
            </div>
        </div>
        )}

      </div>
    </div>
  );
};

export default DocumentPage;