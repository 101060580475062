import React, { useState, useEffect, useRef } from 'react';
import { Search, Bookmark } from 'lucide-react';
import axios from './axiosConfig';
import './DocSearch.css'

const CommunityInsights = ({ dekaName, comments }) => {
    console.log("CommunityInsights comments prop:", comments); // Debugging: Verify the prop

    const [newComment, setNewComment] = useState(""); // Input for new comment
    const [likes, setLikes] = useState({}); // Track likes for each comment

// Add a new comment
const handleAddComment = async () => {
    try {
        const encodedDekaName = encodeURIComponent(`${dekaName.replace('/', '_')}`);
        console.log(`Encoded dekaName: ${encodedDekaName}`);  // Debugging statement

        const response = await axios.post(`/api/documents/${encodedDekaName}/comments`, { 
            userId : "UserId",
            text: newComment, 
            timestamp: new Date().toISOString() 
        });

        // Directly update comments after adding the new comment
        comments.push(response.data);  // Assuming response contains the added comment
        setNewComment("");  // Clear input
    } catch (error) {
        console.error("Error adding comment:", error);
    }
};

// Handle "Like" button click
const handleLike = (index) => {
    setLikes((prevLikes) => ({
        ...prevLikes,
        [index]: (prevLikes[index] || 0) + 1,
    }));
};

const formatTimestamp = (timestamp) => {
    const now = new Date();
    const date = new Date(timestamp);

    const diffInMs = now - date;
    const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
    const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
    const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

    if (diffInMinutes < 60) {
        // Within an hour, show as minutes
        return `${diffInMinutes} min${diffInMinutes !== 1 ? 's' : ''} ago`;
    } else if (diffInHours < 24) {
        // Within a day, show as hours
        return `${diffInHours} h${diffInHours !== 1 ? '' : ''} ago`;
    } else {
        // More than a day ago, show full date with GMT+7
        const hours = date.getUTCHours() + 7; // Adjust to GMT+7
        const minutes = date.getUTCMinutes();
        const day = date.getUTCDate();
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getUTCFullYear();

        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12;
        const formattedMinutes = minutes.toString().padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}${ampm} ${day} ${month} ${year} (GMT+7)`;
    }
};


return (
    <div className="w-1/4 ml-4 flex flex-col text-sm text-gray-600">
      <h4 className="text-xl font-semibold mb-2 text-gray-800">แชร์ความรู้</h4>
      <div className="overflow-y-auto h-80">
        {comments.length > 0 ? (
            comments.map((comment, index) => (
                <div key={index} className="mb-2">
                   {/* UserId and Timestamp on the same row */}
                <div className="flex items-center justify-between">
                    <p className="font-semibold text-gray-700">{comment.userId || "Anonymous"}</p>
                    <p className="text-xs text-gray-500">{formatTimestamp(comment.timestamp)}</p>
                </div>
                <p>{comment.text}</p>
                    <div className="flex items-center mt-1 space-x-2">
                        {/* <button
                            className="text-blue-500 hover:underline"
                            onClick={() => handleLike(index)}
                        >
                            Like ({likes[index] || 0})
                        </button>
                        <button className="text-blue-500 hover:underline">Reply</button> */}
                    </div>
                </div>
            ))
        ) : (
            <p>เริ่มแชร์ความรู้</p>
        )}
      </div>
      <textarea
        value={newComment}
        onChange={(e) => setNewComment(e.target.value)}
        placeholder="แสดงความคิดเห็นเกี่ยวกับฎีกานี้"
        className="mt-2 p-2 border rounded-xl h-11 w-full focus:outline-none focus:ring-1 focus:ring-blue-500"
        rows="3"
      ></textarea>
      <button onClick={handleAddComment} className="mt-2 px-3 py-1 text-white bg-blue-500 rounded">
        แสดงความคิดเห็น
      </button>
    </div>
);
};

// Format message function
function formatMessage(text) {
    const formattedText = text
      .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') // Convert bold syntax to <strong>
      .replace(/\n{2,}/g, '<br /><br />') // Double newlines to new paragraphs
      .replace(/\n/g, '<br />'); // Single newline to line break
  
    return formattedText;
  }



const DocSearch = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [yearFilter, setYearFilter] = useState('');
  const [lawFilter, setLawFilter] = useState('');
  const [sourceFilter, setSourceFilter] = useState('');
  const [dekaId, setDekaId] = useState('');
  const [dekaYear, setDekaYear] = useState('');

  // Category visibility state
  const [categories, setCategories] = useState({
    full_deka: false,
    related_law: true,
    source: true,
    party_name: true,
    department: true,
    current_judge: true,
    case_number: true,
    previous_judge: true,
    initial_case_numbers: true,
  });

  // Mapping for category display names
const categoryDisplayNames = {
    full_deka: 'ย่อยาว',
    related_law: 'กฎหมายที่เกี่ยวข้อง',
    source: 'แหล่งที่มา',
    party_name: 'ชื่อคู่ความ',
    department: 'แผนก',
    current_judge: 'ชื่อองค์คณะ',
    case_number: 'หมายเลขคดีดำ ของศาลฎีกา',
    previous_judge: 'ศาลชั้นต้นและศาลอุทธรณ์',
    initial_case_numbers: 'หมายเลขคดีดำ และ หมายเลขคดีแดง ของศาลชั้นต้น'
  };

  // Dropdown state
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // interaction



    const [savedDocuments, setSavedDocuments] = useState([]); // Add savedDocuments state
/// visible 
    const [visibleRange, setVisibleRange] = useState([0, 0]); // Currently visible range
    const resultsContainerRef = useRef(null); // Reference for the results container

    useEffect(() => {
        // Fetch saved documents from the API on component mount
        const fetchSavedDocuments = async () => {
          try {
            const response = await axios.get('/api/get-saved-documents');
            console.log("Fetched saved documents:", response.data.savedDocuments);  // Debugging log
            setSavedDocuments(response.data.savedDocuments || []);
          } catch (error) {
            console.error("Failed to fetch saved documents:", error);
          }
        };
        fetchSavedDocuments();
      }, []);
    

      // Helper function to check if a document is saved
      const isDocumentSaved = (deka_name) => savedDocuments.some(doc => doc.deka_name === deka_name);

      const handleSaveDocument = async (result) => {
        const isAlreadySaved = isDocumentSaved(result.deka_name);
    
        try {
            if (isAlreadySaved) {
                // Remove document from saved documents
                const updatedDocuments = savedDocuments.filter(doc => doc.deka_name !== result.deka_name);
                setSavedDocuments(updatedDocuments);
                await axios.post('/api/remove-saved-document', { deka_name: result.deka_name });
                console.log(`Removed document: ${result.deka_name}`);  // Debugging log
            } else {
                // Add document to saved documents
                const updatedDocuments = [...savedDocuments, result];
                setSavedDocuments(updatedDocuments);
                await axios.post('/api/save-document', { deka_name: result.deka_name });
                console.log(`Saved document: ${result.deka_name}`);  // Debugging log
            }
        } catch (error) {
            console.error("Error saving/removing document:", error);
        }
    };
    

    const handleSearchQueryChange = (e) => {
        setSearchQuery(e.target.value);
        setDekaId('');        // Clear Deka ID
        setDekaYear('');      // Clear Deka Year
      };
      
      const handleDekaIdChange = (e) => {
        setDekaId(e.target.value);
        setSearchQuery('');    // Clear main search query
      };
      
      const handleDekaYearChange = (e) => {
        setDekaYear(e.target.value);
        setSearchQuery('');    // Clear main search query
      };
      

  const handleSearch = async (e) => {
    e.preventDefault();
    setIsSearchLoading(true);

    setSearchResults({ dekaResults: [], martharResults: [] });
    
    try {
        const response = await axios.post(
          '/api/search',
          { query: searchQuery },
          { headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` } }
        );
  
        const data = response.data;
        console.log("Search results with comments:", data.results); // Verify if comments are present in each result

        if (data.results && Array.isArray(data.results) && data.m_results && Array.isArray(data.m_results)) {
          const dekaResultsWithCheckbox = data.results.map(result => ({ ...result, isChecked: false }));
          const martharResultsWithCheckbox = data.m_results.map(result => ({ ...result, isChecked: false }));
  
          setSearchResults({
            dekaResults: dekaResultsWithCheckbox,
            martharResults: martharResultsWithCheckbox,
          });
      } else {
        console.error('Expected arrays of results but got:', data);
        setSearchResults({ dekaResults: [], martharResults: [] });
      }
  
      setIsSearchLoading(false);
      
    } catch (error) {
      console.error('Error fetching results or answer:', error);
    } finally {
    }
  };

  const handleDekaSearch = async (e) => {
    e.preventDefault();
    setIsSearchLoading(true);
  
    try {
      const response = await axios.post(
        '/api/key-search',
        { dekaId, dekaYear },  // Send dekaId and dekaYear as the payload
        { headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` } }
      );
  
      const data = response.data;
      console.log("Search results with comments:", data.results); // Verify if comments are present in each result

  
      if (data.results && Array.isArray(data.results)) {
        setSearchResults({ dekaResults: data.results });  // Update searchResults with only dekaResults
      } else {
        setSearchResults({ dekaResults: [] });
      }
  
      setIsSearchLoading(false);
    } catch (error) {
      console.error('Error fetching deka search results:', error);
      setIsSearchLoading(false);
    }
  };

  // Scroll handling for updating visible range
  useEffect(() => {
    const handleScroll = () => {
      if (resultsContainerRef.current) {
        const container = resultsContainerRef.current;
        const items = container.querySelectorAll('li');
        const itemHeight = items[0]?.offsetHeight || 0;
        const startIndex = Math.floor(container.scrollTop / itemHeight);
        const visibleItemsCount = Math.floor(container.clientHeight / itemHeight);
        setVisibleRange([
          startIndex + 1,
          Math.min(startIndex + visibleItemsCount, searchResults.dekaResults?.length || 0),
        ]);
      }
    };

    const container = resultsContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      handleScroll();
    }

    return () => {
      if (container) container.removeEventListener('scroll', handleScroll);
    };
  }, [searchResults]);




  const handleCheckboxChange = (resultId) => {
    setSelectedDocuments((prevSelected) => {
        // Toggle the selection of the specific result ID
        if (prevSelected.includes(resultId)) {
            // If already selected, remove it from the array
            return prevSelected.filter((id) => id !== resultId);
        } else {
            // If not selected, add it to the array
            return [...prevSelected, resultId];
        }
    });
};



  
/// display the another window
  const showSelectedDocumentsInHTML = () => {
    const allResults = [
      ...(searchResults.dekaResults || []),
      ...(searchResults.martharResults || [])
    ];
  
    // Filter selected documents based on user selection
    const selectedData = allResults.filter((result) =>
      selectedDocuments.includes(result.deka_name)
    );
  
    const now = new Date();
    const timestamp = now.toLocaleString();
  
    let htmlContent = `
      <html>
        <head>
          <title>Selected Documents</title>
          <style>
            body, html {
              margin: 0;
              padding: 0;
              font-family: Arial, sans-serif;
              color: #333;
              display: flex;
              justify-content: center;
              align-items: center;
              min-height: 100vh;
              width: 100%;
              overflow: auto;
              background-color: #f0f0f0;
            }
            .container {
              width: 210mm;
              margin: auto;
              box-sizing: border-box;
            }
            .document {
              margin: 20px;
              padding: 20px;
              border: 1px solid #ccc;
              background-color: #fff;
              box-sizing: border-box;
            }
            .header {
              display: flex;
              justify-content: center;
              align-items: center;
              margin-bottom: 10px;
              position: relative;
            }
            .timestamp {
              position: absolute;
              left: 0;
              font-size: 12px;
              color: #555;
            }
            .title {
              font-size: 18px;
              font-weight: bold;
              text-align: center;
            }
            .info-columns {
            display: flex;
            justify-content: space-between;
            margin-bottom: 15px;
            }
            .info-item {
            flex: 1;
            text-align: left;
            }
            .document-content {
              margin-top: 10px;
              line-height: 1.6;
            }
            h2 {
              text-align: center;
              font-size: 16px;
              margin: 10px 0;
            }
            hr {
              margin: 20px 0;
              border: none;
              border-top: 1px solid #ccc;
            }
          </style>
        </head>
        <body>
          <div class="container">
    `;

  
    selectedData.forEach((doc) => {

      htmlContent += `
        <div class="document">
          <div class="header">
            <div class="timestamp">${timestamp}</div>
            <div class="title">${doc.deka_name || 'No Title'}</div>
          </div>
          <h2>ฎีกาตัดสินเกี่ยวกับปัญหาข้อกฎหมาย</h2>
          <br>
  
          <div class="info-columns">
            <div class="info-item"><strong> ${doc.deka_name || 'ไม่ระบุ'}</strong></div>
            <div class="info-item"><strong>ชื่อคู่ความ:</strong> ${doc.party_name}</div>
        </div>
  
          <div class="document-content">
            <div><strong>กฎหมายที่เกี่ยวข้อง:</strong></div>
            <ul>${
              Array.isArray(doc.related_law)
                ? doc.related_law.map(law => `<li>${law}</li>`).join('')
                : doc.related_law ? `<li>${doc.related_law}</li>` : 'ไม่ระบุ'
            }</ul>
          <div><strong>ย่อสั้น:</strong> ${formatMessage(doc.short_deka || 'ไม่ระบุ')}</div>
          <div><strong>ย่อยาว:</strong> ${formatMessage(doc.full_deka || 'ไม่ระบุ')}</div>
            <div><strong>แหล่งที่มา:</strong> ${doc.source || 'ไม่ระบุ'}</div>
            <div><strong>แผนก:</strong> ${doc.แผนก || 'ไม่ระบุ'}</div>
            <div><strong>ชื่อองค์คณะ:</strong> ${doc.current_judge || 'ไม่ระบุ'}</div>
            <div><strong>หมายเลขคดีดำ ของศาลฎีกา:</strong> ${doc.หมายเลขคดีดำ || 'ไม่ระบุ'}</div>
            <div><strong>ศาลชั้นต้นและศาลอุทธรณ์:</strong> ${doc.previous_judge || 'ไม่ระบุ'}</div>
            <div><strong>หมายเลขคดีดำ และ หมายเลขคดีแดง ของศาลชั้นต้น:</strong> ${doc.หมายเลขคดีดำและหมายเลขคดีแดงของศาลชั้นต้น || 'ไม่ระบุ'}</div>
          </div>
          <hr />
        </div>
      `;
    });
  
    htmlContent += `
          </div>
        </body>
      </html>
    `;
  
    const newWindow = window.open();
    newWindow.document.write(htmlContent);
    newWindow.document.close();
  };

  
  // Toggle categories
  const handleCategoryChange = (category) => {
    setCategories((prevCategories) => ({
      ...prevCategories,
      [category]: !prevCategories[category],
    }));
  };

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  
  
  const renderSearchResult = (result) => {
    console.log("Rendering result with comments:", result.comments); // Check comments in each result

    const isChecked = selectedDocuments.includes(result.deka_name);
  const isSaved = isDocumentSaved(result.deka_name); // Update this to use the helper function


    return (
        <li key={result.deka_name} className="mb-4 flex">
            {/* Document Content with Rounded Border */}
            <div className="relative flex-1 p-4 border rounded-xl bg-white shadow-sm">
                {/* Save Icon and Checkbox in the top-right corner */}
                <div className="absolute top-4 right-4 flex items-center space-x-2">
                    <Bookmark
                    onClick={() => handleSaveDocument(result)} // Pass a function reference to handleSaveDocument
                    className={`cursor-pointer ${isSaved ? 'text-blue-500' : 'text-gray-400'}`}
                    size={24}
                    title={isSaved ? "Unsave from directory" : "Save to directory"}
                    />
                    <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={() => handleCheckboxChange(result.deka_name)}
                        className="w-5 h-5 text-blue-600 rounded-xl border-gray-300 focus:ring-blue-500"
                    />
                </div>

                {/* Title and Save Icon */}
                <div className="flex items-center justify-between mb-2">
                    <h3 className="text-xl font-semibold">
                        {result.deka_name}
                    </h3>
                </div>
                <br />
                <p className="text-l font-semibold mb-2">ย่อสั้น</p>
                <div
                    dangerouslySetInnerHTML={{ __html: formatMessage(result.short_deka) }}
                    className="text-gray-700 mb-4"
                />

                {categories.full_deka && (
                    <div className="text-gray-700 mb-4">
                        <br />
                        <p className="text-l font-semibold mb-2">ย่อยาว</p>
                        <div dangerouslySetInnerHTML={{ __html: formatMessage(result.full_deka) }} />
                    </div>
                )}

                <div className="grid grid-cols-2 gap-4 mb-4">
                    {categories.related_law && (
                        <div>
                            <strong>ฎีกาอื่นที่เกี่ยวข้อง:</strong>
                            <p className="text-gray-600">{result.related_law}</p>
                        </div>
                    )}
                    {categories.source && (
                        <div>
                            <strong>แหล่งที่มา:</strong>
                            <p className="text-gray-600">{result.source}</p>
                        </div>
                    )}
                    {categories.party_name && (
                        <div>
                            <strong>ชื่อคู่ความ:</strong>
                            <p className="text-gray-600">{result.party_name}</p>
                        </div>
                    )}
                    {categories.department && (
                        <div>
                            <strong>แผนก:</strong>
                            <p className="text-gray-600">{result.แผนก}</p>
                        </div>
                    )}
                    {categories.current_judge && (
                        <div>
                            <strong>ชื่อองค์คณะ:</strong>
                            <p className="text-gray-600">{result.current_judge}</p>
                        </div>
                    )}
                    {categories.case_number && (
                        <div>
                            <strong>หมายเลขคดีดำ ของศาลฎีกา:</strong>
                            <p className="text-gray-600">{result.หมายเลขคดีดำ}</p>
                        </div>
                    )}
                    {categories.previous_judge && (
                        <div>
                            <strong>ศาลชั้นต้นและศาลอุทธรณ์:</strong>
                            <p className="text-gray-600">{result.previous_judge}</p>
                        </div>
                    )}
                    {categories.initial_case_numbers && (
                        <div>
                            <strong>หมายเลขคดีดำ และ หมายเลขคดีแดง ของศาลชั้นต้น:</strong>
                            <p className="text-gray-600">{result.หมายเลขคดีดำและหมายเลขคดีแดงของศาลชั้นต้น}</p>
                        </div>
                    )}

                </div>
            </div>

                


            {/* Community Insights */}
            <CommunityInsights comments={result.comments || []} dekaName={result.deka_name} />
            
        </li>
    );
};




  return (
    <div className="mx-20">
      <div className="px-8 py-4">
        {/* Search form, buttons, and search results */}
        
        <form onSubmit={handleSearch} className="mb-4">
          <div className="relative mb-4">
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchQueryChange}
              placeholder="พิมพ์เนื่อเรื่องเพื่อค้นหา..."
              className="w-full p-4 pr-12 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button type="submit" className="absolute right-4 top-1/2 transform -translate-y-1/2">
              <Search className="text-gray-400" />
            </button>
          </div>
        </form>

       


        {/* Specific Deka Search Form */}
        <form onSubmit={handleDekaSearch} className="mb-4">
          <div className="flex gap-4 items-center mb-4">
            <input
              type="text"
              value={dekaId}
              onChange={handleDekaIdChange}
              placeholder="เลขฎีกา"
              className="flex-1 p-3 rounded-xl border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <input
              type="text"
              value={dekaYear}
              onChange={handleDekaYearChange}
              placeholder="ปีฎีกา"
              className="flex-1 p-3 rounded-xl border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button
              type="submit"
              className={`px-4 py-2 rounded-xl ${
                !dekaId && !dekaYear
                  ? 'bg-gray-300 p-3 text-gray-500 cursor-not-allowed'
                  : 'bg-slate-900 p-3 text-white'
              }`}
              disabled={!dekaId && !dekaYear}
            >
              ค้นหา
            </button>
          </div>
        </form>
         

        <div className="flex items-center justify-between mb-4">
          {/* Button for selected documents on the left */}
          <button
            onClick={showSelectedDocumentsInHTML}
            className={`px-4 py-2 rounded-xl ${
              selectedDocuments.length === 0
                ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                : 'bg-slate-900 text-white'
            }`}
            disabled={selectedDocuments.length === 0}
          >
            เตรียมพิมพ์คำพิพากษาที่เลือก
          </button>

          {/* Category Dropdown Button on the right */}
          <div className="relative">
            <button
              className="px-4 py-2 bg-slate-900 text-white rounded-xl"
              onClick={toggleDropdown}
            >
              รูปแบบการแสดงผล
            </button>
            

            {/* Dropdown with Checkboxes */}
            {isDropdownOpen && (
              <div className="absolute right-0 mt-2 w-64 bg-white border border-gray-200 rounded-lg shadow-lg p-4 z-10">
                {Object.keys(categories).map((category) => (
                  <label key={category} className="flex items-center space-x-2 mb-2">
                    <input
                      type="checkbox"
                      checked={categories[category]}
                      onChange={() => handleCategoryChange(category)}
                    />
                    <span className="capitalize">
                      {categoryDisplayNames[category] || category.replace('_', ' ')}
                    </span>
                  </label>
                ))}
              </div>
            )}
          </div>
        </div>

{/* Results Summary */}
<div className="flex justify-between items-center mb-4">
          <p className="text-lg font-medium">
          แสดง {searchResults.dekaResults?.length || 0} ผลลัพธ์
          </p>
          <p className="text-sm text-gray-500">
          <p>Viewing {visibleRange[0]}</p>
          </p>
        </div>
        <div ref={resultsContainerRef} className="overflow-y-scroll h-[34rem] rounded-lg px-5 scrollbar-always-visible">

        {/* Render Search Results */}
        {isSearchLoading ? (
          <div className="w-full p-4 bg-white rounded-fill mb-4 space-y-4">
            {[...Array(5)].map((_, index) => (
              <div
                key={index}
                className="skeleton h-32 bg-gray-300 rounded-xl w-full mb-4"
              ></div>
            ))}
          </div>
        ) : (
          <ul className="space-y-4">
            {Array.isArray(searchResults.dekaResults) && searchResults.dekaResults.map((result) => renderSearchResult(result))}
          </ul>
        )}
      </div>
    </div>
  </div>
  );
};

export default DocSearch;