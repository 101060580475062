import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "./axiosConfig";

export default function AuthPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [resetEmail, setResetEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);

  const navigate = useNavigate();

  async function handleSignIn(event) {
    event.preventDefault();
    setIsLoading(true);
    setErrorMessage("");
    setSuccessMessage("");

    try {
      const response = await axios.post("/api/auth/signin", { email, password });
      if (response.data.access_token) {
        localStorage.setItem("authToken", response.data.access_token);
        localStorage.setItem("userId", email);
        setTimeout(() => {
          window.location.href = "/";
        }, 100);
      } else {
        throw new Error("No token returned from the API.");
      }
    } catch (error) {
      setErrorMessage(error.response?.data?.message || error.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleResetPasswordSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    setErrorMessage("");
    setSuccessMessage("");

    if (newPassword !== confirmPassword) {
      setPasswordsMatch(false);
      setIsLoading(false);
      return;
    }

    try {
      await axios.post("/api/auth/reset-password", { email: resetEmail, new_password: newPassword });
      setSuccessMessage("Password reset successful! You can now log in with your new password.");
      setShowForgotPasswordModal(false);
    } catch (error) {
      setErrorMessage(error.response?.data?.message || error.message);
    } finally {
      setIsLoading(false);
    }
  }
  function navigateToSubscription() {
    window.location.href = "https://www.khorn-ai.com/subscription";
  }

  return (
    <div style={{ display: "flex", height: "100vh" }}>
      {/* Left Side - Authentication */}
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#f0f4f8",
          flexDirection: "column",
        }}
      >
        <h2 style={{ fontSize: "28px", fontWeight: "600", marginBottom: "20px", color: "#333" }}>
          Hi, Welcome Back
        </h2>
        <div
          style={{
            width: "400px",
            padding: "30px",
            borderRadius: "10px",
            backgroundColor: "#fff",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h1 style={{ fontSize: "24px", fontWeight: "600", marginBottom: "10px" }}>Sign In</h1>
          <p style={{ fontSize: "14px", marginBottom: "20px", color: "#555" }}>
            Sign in to your account to access the workspace.
          </p>

          {errorMessage && (
            <div style={{ color: "red", marginBottom: "10px", textAlign: "center" }}>
              {errorMessage}
            </div>
          )}

          {successMessage && (
            <div style={{ color: "green", marginBottom: "10px", textAlign: "center" }}>
              {successMessage}
            </div>
          )}

          <form onSubmit={handleSignIn}>
            <div style={{ marginBottom: "15px" }}>
              <label
                htmlFor="email"
                style={{ display: "block", marginBottom: "5px", fontWeight: "500" }}
              >
                Email
              </label>
              <input
                id="email"
                type="email"
                placeholder="m@example.com"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{
                  width: "100%",
                  padding: "7px",
                  borderRadius: "4px",
                  border: "1px solid #ccc",
                }}
              />
            </div>

            <div style={{ marginBottom: "15px" }}>
              <label
                htmlFor="password"
                style={{ display: "block", marginBottom: "5px", fontWeight: "500" }}
              >
                Password
              </label>
              <input
                id="password"
                type="password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={{
                  width: "100%",
                  padding: "7px",
                  borderRadius: "4px",
                  border: "1px solid #ccc",
                }}
              />
            </div>

            <div style={{ textAlign: "right", marginBottom: "20px" }}>
              <button
                type="button"
                onClick={() => setShowForgotPasswordModal(true)}
                style={{
                  color: "#000",
                  textDecoration: "none",
                  fontSize: "14px",
                  border: "none",
                  background: "none",
                }}
              >
                Forgot password?
              </button>
            </div>

            <button
              type="submit"
              style={{
                width: "100%",
                padding: "10px",
                backgroundColor: "#000000",
                color: "#fff",
                borderRadius: "4px",
                cursor: "pointer",
              }}
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Sign In"}
            </button>
          </form>

          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <button
              type="button"
              onClick={navigateToSubscription}
              style={{
                color: "gray",
                textDecoration: "none",
                background: "none",
                border: "none",
                fontSize: "14px",
                marginTop: "10px",
              }}
            >
              Not a member? Signup
            </button>
          </div>
        </div>
      </div>

      {/* Forgot Password Modal */}
      {showForgotPasswordModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg w-80">
            <h2 className="text-xl font-semibold mb-4">Reset Password</h2>
            <form onSubmit={handleResetPasswordSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Email</label>
                <input
                  type="email"
                  value={resetEmail}
                  onChange={(e) => setResetEmail(e.target.value)}
                  required
                  placeholder="Enter your email"
                  className="w-full px-3 py-2 border rounded-md focus:outline-none"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">New Password</label>
                <input
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                  placeholder="Enter new password"
                  className="w-full px-3 py-2 border rounded-md focus:outline-none"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Confirm Password</label>
                <input
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    setPasswordsMatch(e.target.value === newPassword);
                  }}
                  required
                  placeholder="Confirm new password"
                  className={`w-full px-3 py-2 border rounded-md focus:outline-none ${
                    passwordsMatch ? "focus:ring-blue-500" : "focus:ring-red-500"
                  }`}
                />
                {!passwordsMatch && <p className="text-red-600 text-sm">Passwords do not match</p>}
              </div>
              <div className="flex justify-between items-center">
                <button
                  type="submit"
                  className="py-2 px-4 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                  disabled={isLoading || !passwordsMatch}
                >
                  {isLoading ? "Processing..." : "Reset Password"}
                </button>
                <button
                  type="button"
                  className="py-2 px-4 text-gray-500 hover:text-gray-700"
                  onClick={() => setShowForgotPasswordModal(false)}
                >
                  Cancel
                </button>
              </div>
            </form>
            {errorMessage && <p className="text-red-600 mt-4">{errorMessage}</p>}
            {successMessage && <p className="text-green-600 mt-4">{successMessage}</p>}
          </div>
        </div>
      )}

      {/* Right Side - Company Details */}
      <div
        style={{
          flex: 1,
          backgroundColor: "#f5f5f5",
          padding: "40px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1 style={{ fontSize: "32px", fontWeight: "700", marginBottom: "20px" }}>
          Welcome to Khorn
        </h1>
        <p
          style={{
            fontSize: "16px",
            textAlign: "center",
            maxWidth: "400px",
            lineHeight: "1.5",
          }}
        >
          Khorn is an AI-powered tool that helps lawyers focus on their core tasks by providing
          efficient access to legal documents and enhanced research capabilities.
        </p>
      </div>
    </div>
  );
}
