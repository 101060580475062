import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';  // Import the useNavigate hook
import NoteEditor from './NoteEditor'; 
import axios from './axiosConfig';
import { Search,Trash2, FileText, Plus, Star, Folder, Settings, Grid2x2, List as ListIcon} from 'lucide-react';

export default function NoteWorkSpace() {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [notes, setNotes] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [newNoteTitle, setNewNoteTitle] = useState('');
  const [currentNote, setCurrentNote] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [viewMode, setViewMode] = useState('gallery');
  const [noteToDelete, setNoteToDelete] = useState(null); // State for note to be deleted
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false); // State for delete confirmation popup
  const [recentNotes, setRecentNotes] = useState([]);
  const navigate = useNavigate();  // Initialize useNavigate
  const [isAuthenticated, setIsAuthenticated] = useState(true); // Assuming you manage authentication state here
  



  // retrieve all available note 
  useEffect(() => {
    const fetchNotes = async () => {
      try {
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('authToken');
        if (!userId || !token) {
          console.error("Missing userId or authToken.");
          return;
        }
  
        const response = await axios.get('/api/notes', {
          params: { userId },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        console.log('Fetched notes:', response.data); // Log the response to check the notes
        setNotes(response.data); // Set the fetched notes
      } catch (error) {
        console.error('Error fetching notes:', error);
      }
    };
  
    fetchNotes();
  }, []);
  
  
  
  

  // Fetch notes and get the 3 most recent ones
  useEffect(() => {
    const sortedNotes = notes
      .sort((a, b) => new Date(b.last_edited) - new Date(a.last_edited))
      .slice(0, 3); // Take only the top 3 most recently edited notes
    setRecentNotes(sortedNotes);
  }, [notes]); // Run this whenever the notes list updates



  useEffect(() => {
    if (currentNote) {
        console.log('Current note in editor:', currentNote);
    }
    }, [currentNote]);

const handleCloseEditor = () => {
    setCurrentNote(null);
    };

  if (currentNote) {
    return <NoteEditor note={currentNote} onClose={handleCloseEditor} />;
  }

  const filteredNotes = notes
    .filter((note) => note.title.toLowerCase().includes(searchQuery.toLowerCase()))
    .sort((a, b) => (new Date(b.lastEdited) - new Date(a.lastEdited)));

// workspace sidebar 
// const handleFavoriteToggle = async (noteId, isFavorite) => {
//   try {
//     const token = localStorage.getItem('authToken');
//     console.log('Token in WorkSpace:', token); // Log the token being used in WorkSpace
//     await axios.patch(`/api/notes/${noteId}`, { favorite: isFavorite });
//     setNotes((prevNotes) =>
//       prevNotes.map((note) => (note.id === noteId ? { ...note, favorite: isFavorite } : note))
//     );
//   } catch (error) {
//     console.error('Error updating favorite status:', error);
//   }
// };
const handleFavoriteToggle = async (noteId, isFavorite) => {
  try {
    await axios.patch(
      `/api/notes/${noteId}`, 
      {
        favorite: isFavorite,  // Update the favorite status
        last_edited: new Date().toISOString(),  // Optionally update the last edited time
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,  // Add Authorization header
        },
      }
    );
    
    // Update the note's favorite status in state
    setNotes(prevNotes =>
      prevNotes.map(note => 
        note.id === noteId ? { ...note, favorite: isFavorite } : note
      )
    );
    
    console.log('Note favorite status updated successfully.');
  } catch (error) {
    console.error('Error updating favorite status:', error.response?.data?.detail || error.message);
  }
};

// create new note
const handleCreateNote = async () => {
  if (newNoteTitle.trim()) {
    const newNote = {
      title: newNoteTitle,
      content: '',
      last_edited: new Date().toISOString(),
      selected_documents: [],
      chat_history: [],
      userId: localStorage.getItem('userId'), // Include userId from localStorage
    };

    try {
      // Retrieve the token from localStorage
      const token = localStorage.getItem('authToken');
      if (!token) {
        console.error("No authentication token found.");
        return;
      }

      // Include the token in the Authorization header
      const response = await axios.post('/api/notes', newNote, {
        headers: {
          Authorization: `Bearer ${token}` // Include token in request headers
        }
      });

      const createdNote = response.data;
      console.log('Note created with ID:', createdNote.id);

      if (createdNote.id) {
        setNotes([createdNote, ...notes]);
        setNewNoteTitle('');
        setIsDialogOpen(false);
        setCurrentNote(createdNote);
        navigate(`/notes/${createdNote.id}`);
      } else {
        console.error('Failed to get the ID of the created note');
      }
    } catch (error) {
      console.error('Error creating note:', error);
    }
  }
};


    


//// On Each note 

// note high-level editing on the workspace 
// title change 
// delete note 
// open note
// toggle view 
// last edit time GMT+7

    const handleTitleChange = (noteId, newTitle) => {
        const updatedNotes = notes.map(note => 
          note.id === noteId ? { ...note, title: newTitle } : note
        );
        setNotes(updatedNotes); // Update the title in the local state
      };
      
      const handleTitleSave = async (noteId) => {
        const note = notes.find(note => note.id === noteId);
        try {
          await axios.patch(
            `/api/notes/${noteId}`, 
            {
              title: note.title,
              last_edited: new Date().toISOString(), // Update the last edited time when the title is saved
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('authToken')}`, // Add Authorization header
              },
            }
          );
          console.log('Note title updated successfully.');
        } catch (error) {
          console.error('Error updating note title:', error);
        }
      };
      
      
      const handleDeleteNote = async (noteId) => {
        try {
          const response = await axios.delete(
            `/api/notes/${noteId}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('authToken')}`, // Add Authorization header
              },
            }
          );
          console.log('Note deleted successfully:', response.data);
      
          // Update the state to remove the deleted note from the list
          setNotes(notes.filter(note => note.id !== noteId));
        } catch (error) {
          console.error('Error deleting note:', error.response?.data?.detail || error.message);
        }
      };
      
    
    

    // Function to trigger delete confirmation popup
        const handleDeleteIconClick = (note) => {
            setNoteToDelete(note);
            setIsConfirmingDelete(true);
        };
        // Function to handle delete confirmation
        const confirmDeleteNote = (noteId) => {
            handleDeleteNote(noteId); // Call your existing delete function
            setIsConfirmingDelete(false); // Close the confirmation popup
        };

        // Function to cancel delete
        const cancelDelete = () => {
            setIsConfirmingDelete(false);
            setNoteToDelete(null);
        };

      // opening note 
      const handleOpenNote = (note) => {
        if (note && note.id) {
            console.log("Navigating to note:", note);
            // Navigate to the NoteEditor page with the selected note's ID in the URL
            navigate(`/notes/${note.id}`);
        } else {
            console.warn('Attempted to open a note without an ID:', note);
        }
    };
    
    // toggle view for note on workspace gallery and list 

    const toggleViewMode = () => {
        setViewMode((prevMode) => (prevMode === 'gallery' ? 'list' : 'gallery'));
        };




    /// setting for each user 
    if (currentNote) {
    return <NoteEditor note={currentNote} onClose={handleCloseEditor} />;
    }



    const toggleSettingsMenu = () => {
      setIsSettingsOpen((prev) => !prev);
    };
  

    // // handle logout logic
    const handleLogout = () => {
      console.log('Logged out');
      localStorage.clear();  // Clear all localStorage
      sessionStorage.clear();  // Clear sessionStorage if you're using it
      setTimeout(() => {
        navigate('/auth');
      }, 200);  // Add a slight delay
    };

    
    
    const handleManageAccount = () => {
      setIsSettingsOpen(false);
      navigate('/account'); // Replace '/account' with the route to your Account page
    };
    


  

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Sidebar */}
      <div className="w-64 bg-white border-r border-gray-200 p-4">
    <button
          onClick={() => navigate("/")}
          className="text-2xl font-bold mb-4"
        >
          KHORN
        </button>
    <nav>
      {/* Document Search Link */}
      <button
            onClick={() => navigate('/document-search')} // Adjust the route to your search page
            className="w-full flex items-center mb-4 p-2 text-left hover:bg-gray-200 rounded-lg"
          >
            <Search className="mr-2 h-4 w-4" /> ค้นหาฎีกา
          </button>

        {/* Favorite Toggle */}
        <h2 className="font-semibold mb-4">Favorites</h2>
        {notes && notes.length > 0 ? (
        notes
            .filter((note) => note.favorite) // Filter only favorite notes
            .map((note) => (
            <div key={note.id} className="cursor-pointer  flex justify-between items-center p-2 hover:bg-gray-100 rounded-lg" onClick={() => handleOpenNote(note)}>
                <div className="flex items-center space-x-2">
                <FileText size={16} />
                <span>{note.title}</span>
                </div>

                {/* Favorite Toggle Icon */}
                <Star
                className={`cursor-pointer ${note.favorite ? 'text-yellow-500' : 'text-gray-400'} hover:text-yellow-600`}
                onClick={() => handleFavoriteToggle(note.id, !note.favorite)} // Toggle favorite status
                size={20}
                />
                
            </div>
            ))
        ) : (
        <p>No favorite notes available</p> // Message if no favorite notes
        )}
        <hr className="my-4" />
        {/* Always show the 3 most recently edited notes */}
        <h2 className="font-semibold mb-2">Recent Notes</h2>
        <ul className="space-y-2">
          {recentNotes.map((note) => (
            <li 
            key={note.id} 
            className="cursor-pointer p-2 hover:bg-gray-100 rounded-lg" 
            onClick={() => handleOpenNote(note)}
            >
            {/* First line with FileText icon and note title in the same line */}
            <div className="flex items-center space-x-2">
              <FileText size={16} />
              <p className="font-semibold text-gray-800">{note.title}</p>
            </div>
            
            {/* Second line for the last edited time */}
            <p className="text-xs text-gray-400">
              Last edited: {new Date(note.last_edited).toLocaleString('th-TH', { timeZone: 'Asia/Bangkok' })}
            </p>
          </li>
          ))}
        </ul>
{/* 
        <hr className="my-4" />

        <h2 className="font-semibold mb-2">Categories</h2>

        <button className="w-full flex items-center mb-2 p-2 text-left hover:bg-gray-200">
        <Folder className="mr-2 h-4 w-4" /> Contracts
        </button>
        <button className="w-full flex items-center mb-2 p-2 text-left hover:bg-gray-200">
        <Folder className="mr-2 h-4 w-4" /> Case Law
        </button>
        <button className="w-full flex items-center mb-2 p-2 text-left hover:bg-gray-200">
        <Folder className="mr-2 h-4 w-4" /> Research
        </button> */}
    </nav>
    </div>



      {/* Main Content */}
      <div className="flex-1 p-8">
        <div className="flex justify-between items-start mb-6">
          {/* Header with Title */}
          <h2 className="text-3xl font-bold">Your Notes</h2>

          {/* Right Section */}
          <div className="relative flex flex-col items-end">
            {/* Profile Button */}
            <button
        onClick={toggleSettingsMenu}
        className="bg-gray-300 text-black p-3 rounded-full shadow mb-2 text-lg font-bold flex items-center justify-center"
        style={{ width: '40px', height: '40px' }}
      >
        {localStorage.getItem('userId')?.charAt(0).toUpperCase() || "U"}
      </button>
      {isSettingsOpen && (
        <div
          className="absolute right-0 w-48 bg-white border border-gray-200 rounded shadow-lg z-50"
          style={{ top: '40px' }} // Adjust the position to appear below the icon
        >
          <button
            onClick={handleManageAccount}
            className="w-full px-4 py-2 text-left hover:bg-gray-100"
          >
            Account (My Plan)
          </button>
          <hr className="my-1 border-gray-200" />
          <button
            onClick={handleLogout}
            className="w-full px-4 py-2 text-left hover:bg-gray-100"
          >
            Logout
          </button>
                      </div>
                    )}

              {/* New Note Button */}
              <button
                className="flex items-center p-2 bg-slate-900 text-white rounded"
                onClick={() => setIsDialogOpen(true)}
              >
                <Plus className="mr-2 h-4 w-4" /> New Note
              </button>
            </div>
          </div>



        {isDialogOpen && (
          <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-6 rounded shadow-lg">
              <h3 className="text-xl font-bold mb-4">Create a New Note</h3>
              <input
                className="w-full p-2 border border-gray-300 rounded mb-4"
                value={newNoteTitle}
                onChange={(e) => setNewNoteTitle(e.target.value)}
                placeholder="Note title"
              />
              <div className="flex justify-end">
                <button className="bg-slate-900 text-white p-2 rounded mr-2" onClick={handleCreateNote}>
                  Create
                </button>
                <button className="bg-gray-300 p-2 rounded" onClick={() => setIsDialogOpen(false)}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="mb-6">
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search notes..."
            className="w-full p-4 pr-12 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* Render notes here */}
        <div className={`h-[calc(100vh-240px)] overflow-y-auto ${isDialogOpen ? 'pointer-events-none' : ''}`}>
        <button className="p-2" onClick={toggleViewMode}>
              {viewMode === 'gallery' ? (
                <ListIcon className="h-6 w-6" />
              ) : (
                <Grid2x2 className="h-6 w-6" />
              )}
            </button>
          {viewMode === 'gallery' ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {filteredNotes.map((note) => (
                <div key={note.id} className="border rounded p-4 bg-white shadow relative">
                {/* Trash Icon for Deleting Note */}
                <Trash2
                    className="absolute top-5 right-3 cursor-pointer text-gray-500 hover:text-red-700"
                    size={20}
                    onClick={() => handleDeleteIconClick(note)} // Show confirmation popup
                />
                <Star
                    className={`absolute cursor-pointer top-5 right-9 ${note.favorite ? 'text-yellow-500' : 'text-gray-400'} hover:text-yellow-600`}
                    onClick={() => handleFavoriteToggle(note.id, !note.favorite)} // Toggle favorite status
                    size={20}
                />
                
                {/* Editable Note Title */}
                <input
                    className="font-bold text-lg w-full mb-2"
                    type="text"
                    value={note.title}
                    onChange={(e) => handleTitleChange(note.id, e.target.value)}
                    onBlur={() => handleTitleSave(note.id)}
                    placeholder="Untitled Note"
                />

                {/* Last Edited Timestamp */}
                <p className="text-xs text-gray-400 mt-2">
                    Last edited: {new Date(note.last_edited).toLocaleString()}
                </p>

                {/* Open Note Button */}
                <button 
                    className="w-full mt-4 p-2 bg-slate-900 text-white rounded"
                    onClick={() => handleOpenNote(note)}
                >
                    Open
                </button>
                </div>
            ))}
            </div>

          ) : (
            <div className="space-y-4">
              {filteredNotes.map((note) => (
            <div key={note.id} className="relative border rounded p-4 bg-white shadow flex justify-between items-center">

                {/* Left section with Note Title and Last Edited Timestamp */}
                <div className="flex-1 pr-4">
                    {/* Editable Note Title */}
                    <input
                        className="font-bold text-lg w-full mb-2"
                        type="text"
                        value={note.title}
                        onChange={(e) => handleTitleChange(note.id, e.target.value)}
                        onBlur={() => handleTitleSave(note.id)}
                        placeholder="Untitled Note"
                    />
                    {/* Last Edited Timestamp */}
                    <p className="text-xs text-gray-400 mt-2">
                        Last edited: {new Date(note.last_edited).toLocaleString()}
                    </p>
                </div>

                {/* Right section with Buttons */}
                <div className="flex items-center space-x-2">
                    {/* Trash Icon for Deleting Note */}
                    <Star
                    className={`absolute cursor-pointer top-9 right-20 ${note.favorite ? 'text-yellow-500' : 'text-gray-400'} hover:text-yellow-600`}
                    onClick={() => handleFavoriteToggle(note.id, !note.favorite)} // Toggle favorite status
                    size={20}
                    />
                    <Trash2
                        className="absolute top-9 right-14 cursor-pointer text-gray-500 hover:text-red-700 transform transition-transform duration-200 hover:scale-125"
                        size={20}
                        onClick={() => handleDeleteIconClick(note)} // Show confirmation popup
                    />
                    {/* Open Note Button */}
                    <button 
                        className="bg-slate-900 text-white p-2 rounded"
                        onClick={() => handleOpenNote(note)}
                    >
                        >
                    </button>

                    
                </div>
            </div>
        ))}
            </div>
          )}
        </div>
        {/* Delete Confirmation Popup */}
        {isConfirmingDelete && noteToDelete && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-6 rounded shadow-lg">
                        <h3 className="text-xl font-bold mb-4">
                            Deleting "{noteToDelete.title}" note?
                        </h3>
                        <div className="flex justify-end">
                            <button 
                                className="bg-red-500 text-white p-2 rounded mr-2"
                                onClick={() => confirmDeleteNote(noteToDelete.id)}
                            >
                                Yes
                            </button>
                            <button 
                                className="bg-gray-300 p-2 rounded"
                                onClick={cancelDelete}
                            >
                                No
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    
    
    </div>
  );
}
