import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from './axiosConfig';

const NavigationBar = () => {
  const [accountType, setAccountType] = useState(null);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAccountType = async () => {
      try {
        const token = localStorage.getItem('authToken');
        if (!token) return;

        const response = await axios.get('/api/auth/user-info', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setAccountType(response.data.account_type);
      } catch (error) {
        console.error('Error fetching account type:', error);
      }
    };

    fetchAccountType();
  }, []);

  const toggleSettingsMenu = () => {
    setIsSettingsOpen((prev) => !prev);
  };

  const handleLogout = () => {
    console.log('Logged out');
    localStorage.clear();
    sessionStorage.clear();
    setTimeout(() => {
      navigate('/auth');
    }, 200);
  };

  const handleManageAccount = () => {
    setIsSettingsOpen(false);
    navigate('/account');
  };

  return (
    <header className="flex items-center justify-between p-5 border mt-4 rounded-xl">
      <Link to="/" className="text-3xl font-bold">
        K
      </Link>
      <nav className="ml-auto flex items-center">
        <ul className="flex space-x-6">
          <li>
            <Link to="/note-workspace" className="text-gray-600 hover:text-gray-900">
              Notes
            </Link>
          </li>
          <li>
            <Link to="/review-workspace" className="text-gray-600 hover:text-gray-900">
              Review
            </Link>
          </li>
          <li>
            <Link to="/document-search" className="text-gray-600 hover:text-gray-900">
              Search
            </Link>
          </li>
          <li>
            <Link to="/saved" className="text-gray-600 hover:text-gray-900">
              Saved
            </Link>
          </li>
        </ul>
        {/* Conditionally render the UPGRADE button for free users only */}
          {/* {accountType === 'free' && (
            <li>
              <a href="app.khorn-ai.com/subscription" className="bg-black text-white px-4 py-2 rounded-md">
                UPGRADE
              </a>
            </li>
          )} */}

        {/* Profile Icon Dropdown */}
        <div className="relative ml-5" >
          <button
            onClick={toggleSettingsMenu}
            className="bg-gray-300 text-black p-3 rounded-full shadow text-lg font-bold flex items-center justify-center"
            style={{ width: '40px', height: '40px' }}
          >
            {localStorage.getItem('userId')?.charAt(0).toUpperCase() || 'U'}
          </button>
          {isSettingsOpen && (
            <div
              className="absolute right-0 w-48 bg-white border border-gray-200 rounded shadow-lg z-50"
              style={{ top: '40px' }} // Adjust the position to appear below the icon
            >
              <button
                onClick={handleManageAccount}
                className="w-full px-4 py-2 text-left hover:bg-gray-100"
              >
                Account (My Plan)
              </button>
              <hr className="my-1 border-gray-200" />
              <button
                onClick={handleLogout}
                className="w-full px-4 py-2 text-left hover:bg-gray-100"
              >
                Logout
              </button>
            </div>
          )}
        </div>
      </nav>
    </header>
  );
};

export default NavigationBar;
