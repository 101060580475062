import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import axios from './axiosConfig';
import NoteWorkSpace from './NoteWorkSpace';
import NoteEditor from './NoteEditor';
import AuthPage from './AuthPage';
import DocSearch from './DocSearch';
import SavedDocument from './SavedDocument';
import DocumentPage from './DocumentPage';
import NavigationBar from './NavigationBar';
import DocReview from './DocReview';
import AccountPage from './AccountPage';
import ChangePlanPage from './ChangePlan';
import ChangePlanConfirmPage from './ChangePlanConfirm';
import KhornWorkSpace from './KhornWorkSpace';

function isTokenExpired(token) {
  const decodedToken = JSON.parse(atob(token.split('.')[1]));
  const currentTime = Date.now() / 1000; // in seconds
  return decodedToken.exp < currentTime;
}

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token && !isTokenExpired(token)) {
      axios
        .get('/api/auth/verify', {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          if (response.data.status === 'valid') {
            setIsAuthenticated(true);
          } else {
            setIsAuthenticated(false);
          }
        })
        .catch(() => {
          setIsAuthenticated(false);
        })
        .finally(() => setLoading(false));
    } else {
      localStorage.removeItem('authToken');
      setIsAuthenticated(false);
      setLoading(false);
    }
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    setIsAuthenticated(false);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <div className="mx-20">
        <ConditionalNavigationBar isAuthenticated={isAuthenticated} />
      </div>
      <Routes>
        <Route path="/auth" element={<AuthPage />} />
        <Route
          path="/"
          element={isAuthenticated ? <KhornWorkSpace /> : <Navigate to="/auth" />}
        />
        <Route
          path="/note-workspace"
          element={isAuthenticated ? <NoteWorkSpace /> : <Navigate to="/auth" />}
        />
        <Route
          path="/review-workspace"
          element={isAuthenticated ? <DocReview /> : <Navigate to="/auth" />}
        />
        <Route
          path="/notes/:noteId"
          element={isAuthenticated ? <NoteEditor /> : <Navigate to="/auth" />}
        />
        <Route
          path="/saved"
          element={isAuthenticated ? <SavedDocument /> : <Navigate to="/auth" />}
        />
        <Route
          path="/document-search"
          element={isAuthenticated ? <DocSearch /> : <Navigate to="/auth" />}
        />
        <Route path="/document/:id" element={<DocumentPage />} />
        <Route
          path="/account"
          element={isAuthenticated ? <AccountPage /> : <Navigate to="/auth" />}
        />
        <Route
          path="/change-plan"
          element={isAuthenticated ? <ChangePlanPage /> : <Navigate to="/auth" />}
        />
        <Route
          path="/change-plan-confirm"
          element={isAuthenticated ? <ChangePlanConfirmPage /> : <Navigate to="/auth" />}
        />
        <Route path="*" element={<Navigate to={isAuthenticated ? "/" : "/auth"} />} />
      </Routes>
    </Router>
  );
}

function ConditionalNavigationBar({ isAuthenticated }) {
  const location = useLocation();
  const showNavBarRoutes = [
    '/document-search',
    '/saved',
    '/review-workspace',
    '/account',
    '/change-plan',
  ];

  const documentPattern = /^\/document\/[^/]+$/;
  const shouldShowNavBar = showNavBarRoutes.some((route) => location.pathname.startsWith(route)) || documentPattern.test(location.pathname);

  return shouldShowNavBar ? <NavigationBar isAuthenticated={isAuthenticated} /> : null;
}

export default App;
